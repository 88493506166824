<template>
  <div class="w-100">
    <div class="position-relative">
      <img class="d-none" :src="value.source" ref="image" />
      <div class="position-sticky mb-2 d-flex flex-wrap" style="left: 0">
        <div class="customerButton" v-if="Object.keys(plots).length == 0">
          <b-button @click="setZones('All')" size="sm" variant="primary">
            Show All Plots</b-button
          >
        </div>
        <div class="customerButton" v-else>
          <b-button @click="setZones('Selected')" size="sm" variant="primary">
            Show Plot</b-button
          >
        </div>
        <div class="customerButton">
          <b-button @click="setZones('Vacant')" size="sm" variant="success">
            Show Vacant Plot</b-button
          >
        </div>
        <div class="customerButton">
          <b-button @click="downloadPdf" size="sm" variant="success"> Download</b-button>
        </div>
        <div class="customerButton w-100">
          <b-button @click="handleCancel()" size="sm" variant="danger"> Cancel</b-button>
        </div>
      </div>
    </div>

    <!-- <el-button type="primary" @click="openDialog">Edit</el-button> -->
    <!-- <b-row> -->
    <!-- <b-col class="d-flex justify-content-between align-items-center" md="6">
        <b-button size="sm" variant="primary" @click="handleEdit()">
          Start Edit
        </b-button>
      </b-col>
      <b-col md="6">
        <b-button size="sm" variant="primary" @click="handelStopEdit()">
          Stop Edit
        </b-button>
      </b-col>
    </b-row> -->
    <!-- <b-media v-if="!centerDialogVisible" no-body>
      <b-media-aside>
        <b-link>
          <b-img rounded :src="value.source" style="width: 100%; height: 100%;" />
        </b-link>
      </b-media-aside>
    </b-media> -->
    <div
      class="w-100 d-flex flex-wrap"
      style="overflow-y: hidden !important"
      id="plotmapping"
    >
      <div class="d-none w-100">
        <div>
          <h3>{{ selectedProject ? selectedProject.projectname : "" }}</h3>
        </div>
      </div>
      <div class="d-flex transparent-bg flex-column">
        <div
          :ref="'zonearea-wrap-' + value.uuid"
          class="w-100 h-100 overflow-auto position-relative"
        >
          <div
            class="text-center zonearea"
            :style="{
              backgroundImage: 'url(' + value.source + ')',
              width: '1100px',
              height: '900px',
              backgroundSize: '100% 100%',
            }"
          >
            <div
              v-on:mouseleave="ClickTool(index)"
              v-on:mouseenter="ClickTool(index)"
              :id="index"
              v-for="(zone, index) in zones"
              :key="index"
              class="zone"
              :class="getColor(zone.plotData.status)"
              :zone-id="index"
              :style="{
                width: zone.w + 'px',
                height: zone.h + 'px',
                top: zone.y + bgTopBase - 100 + 'px',
                left: zone.x + bgLeftBase + 'px',
                // 'z-index': zone.i || index + 1,
              }"
            >
              <div
                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                {{ zone.plotData.plotName }}
              </div>
              <div v-if="active[index]" class="hoverview">
                <b-row>
                  <b-col sm="12">
                    <label
                      style="display: flex; justify-content: start !important"
                      class="pl-1"
                      >PlotName&nbsp;: {{ zone.plotData.plotName }}</label
                    >
                  </b-col>
                  <b-col style="display: flex; justify-content: start !important" sm="12">
                    <label class="pl-1"
                      >PlotAreaMeter&nbsp;: {{ zone.plotData.plotAreaMeter }}</label
                    >
                  </b-col>
                  <b-col style="display: flex; justify-content: start !important" sm="12">
                    <label class="pl-1"
                      >PlotAreaYard&nbsp;: {{ zone.plotData.plotAreaYard }}</label
                    >
                  </b-col>
                  <b-col
                    style="display: flex; justify-content: start !important"
                    sm="12"
                    v-if="zone.plotData.plotCornerPhase"
                  >
                    <label class="pl-1"
                      >PlotAreaYard&nbsp;: {{ zone.plotData.plotCornerPhase }}</label
                    >
                  </b-col>
                  <b-col
                    style="display: flex; justify-content: start !important"
                    sm="12"
                    v-if="zone.plotData.plotGardenPhase"
                  >
                    <label class="pl-1"
                      >PlotAreaYard&nbsp;: {{ zone.plotData.plotGardenPhase }}</label
                    >
                  </b-col>
                  <b-col style="display: flex; justify-content: start !important" sm="12">
                    <label class="pl-1"
                      >Place Facing&nbsp;: {{ zone.plotData.placefacing }}</label
                    >
                  </b-col>
                  <b-col style="display: flex; justify-content: start !important" sm="12">
                    <label class="pl-1">Status&nbsp;: {{ zone.plotData.status }}</label>
                  </b-col>

                  <b-col
                    v-if="booking && zone.plotData.status == 'Vacant'"
                    style="display: flex; justify-content: end !important"
                    sm="12"
                  >
                    <b-button size="sm" variant="primary" @click="handleBook(zone)">
                      Book
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </el-dialog> -->
    <div class="text-center my-3"></div>
    <!-- <b-row>
      <b-col class="mt-3" md="12">
        <table class="table table-bordered">
          <thead class="text">
            <tr>
              <th>Select</th>
              <th>Plot No</th>
              <th>SQ. MTR</th>
              <th>SQ. YARD</th>
              <th>Action</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, id) in plotOption" :key="id">
              <td>
                {{ id + 1 }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.totalarea_sqmt }}
              </td>
              <td>
                {{ item.totalarea_sqyds }}
              </td>
              <td>

                <b-button size="sm" variant="primary" @click="handleEdit(item.id, item, item.direction,item.totalarea_sqmt,item.totalarea_sqyds)">
                  View
                </b-button>
              </td>
              <td>
                {{ item.direction }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BBadge,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BDropdown,
  BDropdownItem,
  BImgLazy,
  BMedia,
  BMediaAside,
  BLink,
  BImg,
  BTooltip,
} from "bootstrap-vue";
export default {
  name: "ImageMap",
  components: {
    BButton,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BLink,
    BImg,
    BTooltip,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    plotOption: {
      type: Array,
    },
    booking: {
      type: Boolean,
      default: false,
    },
    plots: {
      type: Object,
      default: {},
    },
    selectedProject: {},
  },
  data() {
    return {
      centerDialogVisible: false,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      plotAreaYard: "",
      plotAreaMeter: "",
      overRange: false,
      resizeZoneId: null,
      resizeZoneEl: null,
      ploltId: "",
      resizeZoneType: null,
      resizeZoneLastXY: {
        x: 0,
        y: 0,
      },
      zones: this.value.zones || [],
      initZones: [],
      zoneMinSize: 4,
      dragging: false,
      EditZone: false,
      beforeMoving: false,
      moving: false,
      tempIndex: null,
      active: false,
      moveXy: {
        x: 0,
        y: 0,
      },
      zoneXy: {
        x: 0,
        y: 0,
      },
      innerVisible: false,
      form: {
        link: null,
      },
      modifiedZoneIndex: null,
      bgWidth: 750,
      bgHeight: 240,
      bgTopBase: null,
      bgLeftBase: null,
      plotId: "",
      disableValue: false,
      isMobile: window.innerWidth,
    };
  },
  mounted() {
    this.value.source = this.value.source.includes(this.imagePath)
      ? this.value.source
      : this.imagePath + this.value.source;
    if (this.zones) {
      const initZones = JSON.parse(JSON.stringify(this.zones));

      this.initZones = initZones;
    }
    this.$refs.image.onload = () => {
      this.bgWidth = this.$refs.image.naturalWidth;
      this.bgHeight = this.$refs.image.naturalHeight;
    };
    this.centerDialogVisible = true;
    this.EditZone = false;
    this.disableValue = true;
  },
  methods: {
    downloadPdf() {
      const divToPrint = document.getElementById("plotmapping");
      const newWin = window.open("", "_blank");

      // Get all the stylesheets in the current document
      const cssLinks = Array.from(
        document.querySelectorAll("link[rel='stylesheet'], style")
      );

      // Write the content and head (including stylesheets) to the new window
      newWin.document.write(`
          <html>
            <head>
              <title>Print Preview</title>
              ${cssLinks.map((link) => link.outerHTML).join("\n")}
              <style>
                    .bg-success {
                      background-color: #28c76f !important
                    }

                    .bg-info {
                      background-color: #00cfe8 !important;
                    }

                    .bg-yellow {
                      background-color: #eded3c;
                    }

                    .bg-warning {
                      background-color: #ff9f43 !important;
                    }

                    .bg-danger {
                      background-color: #ea5455 !important;
                    }
                    #plotmapping >div{
                      display:block !important
                    }
                    h3 {
                      margin: 23px;
                      text-align:center;
                      color: black;
                    }

              </style>
            </head>
            <body>
              ${divToPrint.outerHTML}
            </body>
          </html>
        `);
      newWin.document.close();

      // Trigger the print dialog
      newWin.focus();
      newWin.print();
    },
    handleCancel() {
      this.zones = [];
    },
    handleBook(zone) {
      if (this.booking) {
        this.$emit("BookPlot", zone);
      }
    },
    getColor(value) {
      if (value == "Vacant") return "bg-success";
      else if (value == "Booked") return "bg-info";
      else if (value == "Sold") return "bg-yellow";
      else if (value == "Hold") return "bg-warning";
      else if (value == "Registered") return "bg-danger";
      else {
        return "dark";
      }
    },
    setZones(name) {
      const arr = [];
      this.plotOption.map((item) => {
        if (
          item.direction !== null &&
          (name == "All" ? true : name == "Selected" ? this.plots[item.id] : true) &&
          (name == "Vacant" ? item.status == "Vacant" : true)
        ) {
          JSON.parse(item.direction).map((data) => {
            const plotData = {
              plotAreaYard: item.plotareayd,
              plotAreaMeter: item.plotareasqmtr,
              plotName: item.plotname,
              plotId: item.id,
              plotGardenPhase: item.cornerphase == "Yes" ? item.gardenphasevalue : null,
              plotCornerPhase: item.gardenphase == "Yes" ? item.cornerphasevalue : null,
              placefacing: item.placefacing,
              status: item.status,
            };
            data = { plotData, ...data };
            arr.push(data);
          });
        }
      });
      this.zones = [];
      this.zones = arr;
      this.$nextTick(() => {
        const $zonearea = this.$refs[`zonearea-wrap-${this.value.uuid}`].querySelector(
          ".zonearea"
        );
        const dialog = this.$refs[`zonearea-wrap-${this.value.uuid}`].parentElement
          .parentElement.parentElement;
        this.bgLeftBase =
          $zonearea.getBoundingClientRect().x - dialog.getBoundingClientRect().x + 15;
        this.bgTopBase =
          $zonearea.getBoundingClientRect().y - dialog.getBoundingClientRect().y;
        console.log(dialog.getBoundingClientRect().y);
      });
    },
    ClickTool(index) {
      if (this.active[index] == null) {
        this.active = {};
        this.active[index] = false;
      }
      if (this.active[index] == true) {
        this.active = {};
      } else {
        this.active[index] = this.active[index] != true;
      }
      this.$forceUpdate();
    },
    handelStartMedger(id) {},
    zoneClicked(e) {},
    handleEdit(id, item, direction, plotAreaYard, plotAreaMeter) {
      this.plotId = id;
      this.centerDialogVisible = true;
      this.EditZone = false;
      this.disableValue = true;
      if (direction !== null) {
        this.zones = JSON.parse(direction);
      } else {
        this.zones = [];
      }
      this.$nextTick(() => {
        const $zonearea = this.$refs[`zonearea-wrap-${this.value.uuid}`].querySelector(
          ".zonearea"
        );
        const dialog = this.$refs[`zonearea-wrap-${this.value.uuid}`].parentElement
          .parentElement.parentElement;
        this.bgLeftBase =
          $zonearea.getBoundingClientRect().x - dialog.getBoundingClientRect().x;
        this.bgTopBase =
          $zonearea.getBoundingClientRect().y - dialog.getBoundingClientRect().y;
      });
    },
    handelStopEdit() {
      this.centerDialogVisible = false;
      this.EditZone = true;
    },
    createZone(evt) {
      if (!this.beforeMoving) {
        if (evt.srcElement.classList.value.split(" ").indexOf("zonearea") === -1) {
          return;
        }
        const [x, y] = [evt.x, evt.y];
        const $zonearea = evt.srcElement.parentElement;
        const areaX = $zonearea.getBoundingClientRect().x;
        const areaY = $zonearea.getBoundingClientRect().y;
        const id = this.addZone({
          x: x - areaX,
          y: y - areaY,
        });
        this.$nextTick(() => {
          this.resizeZoneStart(evt, id, "bottomright");
        });
      }
    },
    addZone(zone) {
      let newZone = Object.assign(
        {
          w: this.zoneMinSize,
          h: this.zoneMinSize,
          x: 0,
          y: 0,
          i: 0,
          data: {},
        },
        zone
      );
      newZone = this.zoneRangeFilter(newZone);
      const index = this.zones.push(newZone);
      return index - 1;
    },
    zoneRangeFilter(zone) {
      const newZone = zone;
      const $zonearea = this.$refs[`zonearea-wrap-${this.value.uuid}`].querySelector(
        ".zonearea"
      );
      const [clientWidth, clientHeight] = [$zonearea.clientWidth, $zonearea.clientHeight];
      if (zone.x < 0) {
        newZone.x = 0;
      }
      if (zone.x + zone.w > clientWidth) {
        if (zone.w < clientWidth) {
          newZone.x = clientWidth - zone.w;
        } else if (zone.x < clientWidth) {
          newZone.w = clientWidth - zone.x;
        } else {
          newZone.x = 0;
          newZone.w = clientWidth;
        }
      }

      if (zone.y < 0) {
        newZone.y = 0;
      }

      if (zone.y + zone.h > clientHeight) {
        if (zone.h < clientHeight) {
          newZone.y = clientHeight - zone.h;
        } else if (zone.y < clientHeight) {
          newZone.h = clientHeight - zone.y;
        } else {
          newZone.y = 0;
          newZone.h = clientHeight;
        }
      }

      if (zone.w < this.zoneMinSize) {
        newZone.w = this.zoneMinSize;
      }

      if (zone.h < this.zoneMinSize) {
        newZone.h = this.zoneMinSize;
      }
      return newZone;
    },
    resizeZoneStart(evt, id, type) {
      this.dragging = true;
      const $zone = this.$refs[`zonearea-wrap-${this.value.uuid}`]
        .querySelector(".zonearea")
        .querySelector(`[zone-id="${id}"]`);
      this.resizeZoneEl = $zone;
      this.resizeZoneId = id;
      this.resizeZoneType = type;
      this.resizeZoneLastXY.x = evt.x;
      this.resizeZoneLastXY.y = evt.y;
    },
    resizeZoneMove(evt) {
      if (this.dragging) {
        const zone = this.zones[this.resizeZoneId];
        const $zonearea = this.$refs[`zonearea-wrap-${this.value.uuid}`].querySelector(
          ".zonearea"
        );
        const $zone = this.$refs[`zonearea-wrap-${this.value.uuid}`]
          .querySelector(".zonearea")
          .querySelector(`[zone-id="${this.resizeZoneId}"]`);
        let x = +zone.x;
        let y = +zone.y;
        let w = +zone.w;
        let h = +zone.h;
        const evtx = evt.x;
        const evty = evt.y;
        let ox;
        let oy;
        let ow;
        let oh;
        this.resizeClean();
        this.resizeZoneEl.classList.add(`resize-${this.resizeZoneType}`);
        this.resizeZoneEl.classList.add("resize");
        $zonearea.classList.add(`resize-${this.resizeZoneType}`);

        if (/right/.test(this.resizeZoneType)) {
          ow = w;
          w += evtx - this.resizeZoneLastXY.x;

          if (w < this.zoneMinSize) {
            this.resizeClean();

            this.resizeZoneType = this.resizeZoneType.replace("right", "left");
            this.resizeZoneEl.classList.add(`resize-${this.resizeZoneType}`);
            this.resizeZoneEl.classList.add("resize");
            $zonearea.classList.add(`resize-${this.resizeZoneType}`);

            if (w > -this.zoneMinSize) {
              zone.w = this.zoneMinSize;
              zone.x -= zone.w;
              this.resizeZoneLastXY.x = $zone.getBoundingClientRect().x - zone.w;
            } else {
              zone.w = Math.abs(w);
              zone.x -= zone.w;
              this.resizeZoneLastXY.x = $zone.getBoundingClientRect().x - zone.w;
            }

            return;
          }

          if (w + x >= $zonearea.clientWidth) {
            w = $zonearea.clientWidth - x;
            this.overRange = true;
          } else {
            this.overRange = false;
          }
        }

        if (/left/.test(this.resizeZoneType)) {
          ox = x;
          ow = w;
          x += evtx - this.resizeZoneLastXY.x;
          w -= evtx - this.resizeZoneLastXY.x;

          if (w < this.zoneMinSize) {
            this.resizeClean();

            this.resizeZoneType = this.resizeZoneType.replace("left", "right");
            this.resizeZoneEl.classList.add(`resize-${this.resizeZoneType}`);
            this.resizeZoneEl.classList.add("resize");
            $zonearea.classList.add(`resize-${this.resizeZoneType}`);

            if (w > -this.zoneMinSize) {
              zone.w = this.zoneMinSize;
              zone.x += ow;
              this.resizeZoneLastXY.x = $zone.getBoundingClientRect().x + ow;
            } else {
              zone.w = Math.abs(w + ow);
              zone.x += ow;
              this.resizeZoneLastXY.x = $zone.getBoundingClientRect().x + ow;
            }

            return;
          }

          if (x <= 0) {
            x = 0;
            w = ow - (x - ox);
            this.overRange = true;
          } else {
            this.overRange = false;
          }
        }

        if (/bottom/.test(this.resizeZoneType)) {
          oh = h;
          h += evty - this.resizeZoneLastXY.y;

          if (h < this.zoneMinSize) {
            this.resizeClean();

            this.resizeZoneType = this.resizeZoneType.replace("bottom", "top");
            this.resizeZoneEl.classList.add(`resize-${this.resizeZoneType}`);
            this.resizeZoneEl.classList.add("resize");
            $zonearea.classList.add(`resize-${this.resizeZoneType}`);

            if (h > -this.zoneMinSize) {
              zone.h = this.zoneMinSize;
              zone.y -= zone.h;
              this.resizeZoneLastXY.y = $zone.getBoundingClientRect().y - zone.h;
            } else {
              zone.h = Math.abs(h);
              zone.y -= zone.h;
              this.resizeZoneLastXY.y = $zone.getBoundingClientRect().y - zone.h;
            }

            return;
          }

          if (h + y >= $zonearea.clientHeight) {
            h = $zonearea.clientHeight - y;
            this.overRange = true;
          } else {
            this.overRange = false;
          }
        }

        if (/top/.test(this.resizeZoneType)) {
          oy = y;
          oh = h;
          y += evty - this.resizeZoneLastXY.y;
          h -= evty - this.resizeZoneLastXY.y;

          if (h < this.zoneMinSize) {
            this.resizeClean();
            this.resizeZoneType = this.resizeZoneType.replace("top", "bottom");
            this.resizeZoneEl.classList.add(`resize-${this.resizeZoneType}`);
            this.resizeZoneEl.classList.add("resize");
            $zonearea.classList.add(`resize-${this.resizeZoneType}`);

            if (h > -this.zoneMinSize) {
              zone.h = this.zoneMinSize;
              zone.y += oh;
              this.resizeZoneLastXY.y = $zone.getBoundingClientRect().y + oh;
            } else {
              zone.h = Math.abs(h + oh);
              zone.y += oh;
              this.resizeZoneLastXY.y = $zone.getBoundingClientRect().y + oh;
            }

            return;
          }

          if (y <= 0) {
            y = 0;
            h = oh - (y - oy);
            this.overRange = true;
          } else {
            this.overRange = false;
          }
        }

        this.resizeZoneLastXY.x = evtx;
        this.resizeZoneLastXY.y = evty;

        zone.x = x;
        zone.y = y;
        zone.h = h;
        zone.w = w;
      }
    },
    resizeClean() {
      const $zonearea = this.$refs[`zonearea-wrap-${this.value.uuid}`].querySelector(
        ".zonearea"
      );
      this.resizeZoneEl.classList.remove("resize-top");
      this.resizeZoneEl.classList.remove("resize-bottom");
      this.resizeZoneEl.classList.remove("resize-left");
      this.resizeZoneEl.classList.remove("resize-right");
      this.resizeZoneEl.classList.remove("resize-topleft");
      this.resizeZoneEl.classList.remove("resize-topright");
      this.resizeZoneEl.classList.remove("resize-bottomleft");
      this.resizeZoneEl.classList.remove("resize-bottomright");
      this.resizeZoneEl.classList.remove("resize");

      $zonearea.classList.remove("resize-top");
      $zonearea.classList.remove("resize-bottom");
      $zonearea.classList.remove("resize-left");
      $zonearea.classList.remove("resize-right");
      $zonearea.classList.remove("resize-topleft");
      $zonearea.classList.remove("resize-topright");
      $zonearea.classList.remove("resize-bottomleft");
      $zonearea.classList.remove("resize-bottomright");
    },
    resizeZoneStop(evt) {
      if (this.dragging) {
        evt.stopPropagation();
        this.resizeClean();
        this.overRange = false;
        this.dragging = false;
      }
    },
    moveItemRecord(evt, index) {
      this.beforeMoving = true;
      this.moveXy = {
        x: evt.x,
        y: evt.y,
      };
      this.zoneXy = { x: this.zones[index].x, y: this.zones[index].y };
    },
    moveItem(evt, index) {
      if (this.beforeMoving && !this.dragging) {
        this.moving = true;
        const $zonearea = this.$refs[`zonearea-wrap-${this.value.uuid}`].querySelector(
          ".zonearea"
        );
        const [clientWidth, clientHeight] = [
          $zonearea.clientWidth,
          $zonearea.clientHeight,
        ];
        const moveX = this.zoneXy.x + (evt.x - this.moveXy.x);
        const moveY = this.zoneXy.y + (evt.y - this.moveXy.y);
        const maxX = clientWidth - this.zones[index].w;
        const maxY = clientHeight - this.zones[index].h;
        this.tempIndex = index;
        this.zones[index].i = 999;
        if (moveX > 0 && moveX < maxX) {
          this.zones[index].x = moveX;
        } else {
          this.moveStop();
        }
        if (moveY > 0 && moveY < maxY) {
          this.zones[index].y = moveY;
        } else {
          this.moveStop();
        }
      }
    },
    moveStop() {
      if (this.beforeMoving && this.moving && !this.dragging) {
        this.zones[this.tempIndex].i = this.tempIndex;
        this.tempIndex = null;
        this.beforeMoving = false;
        this.moveXy = { x: 0, y: 0 };
        this.zoneXy = { x: 0, y: 0 };
      }
    },
    openZoneModify(index) {
      this.modifiedZoneIndex = index;
      this.form.link = this.zones[this.modifiedZoneIndex].data.link;
      this.innerVisible = true;
    },
    handleZoneSubmit(evt) {
      evt.preventDefault();
      this.innerVisible = false;
      this.zones[this.modifiedZoneIndex].data.link = this.form.link;
      this.modifiedZoneIndex = null;
    },
    handleZoneRemove(evt) {
      evt.preventDefault();
      this.innerVisible = false;
      this.zones.splice(this.modifiedZoneIndex, 1);
      this.modifiedZoneIndex = null;
    },
    handleZoneCancel() {
      this.innerVisible = false;
      this.form.link = this.zones[this.modifiedZoneIndex].data.link;
      this.modifiedZoneIndex = null;
    },
    setDataInTable() {
      this.zoneData = [];
      this.zones.map((item) => {
        const data = {
          h: item.h,
          i: item.i,
          w: item.w,
          x: item.x,
          y: item.y,
        };
        this.zoneData.push(data);
      });
      this.plotOption.map((item) => {
        if (item.id == this.plotId) {
          item.direction = this.zoneData;
        }
      });
    },
    handleMapSubmit() {
      this.centerDialogVisible = false;
      this.EditZone = true;
      this.$emit("input", this.zones);
      this.setDataInTable();
    },
    handleMapCancel() {
      this.zones = JSON.parse(JSON.stringify(this.initZones));
      this.centerDialogVisible = false;
      this.EditZone = true;
    },
  },
};
</script>

<style lang="scss" scoped>
$grid-bg: #ccc;
.customerButton {
  padding: 5px;
}
.transparent-bg {
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
  background-image: linear-gradient(
      45deg,
      $grid-bg 25%,
      transparent 25%,
      transparent 75%,
      $grid-bg 75%,
      $grid-bg
    ),
    linear-gradient(
      45deg,
      $grid-bg 25%,
      transparent 25%,
      transparent 75%,
      $grid-bg 75%,
      $grid-bg
    );
}

.zonearea {
  display: block;
  cursor: crosshair;
  font-size: 14px;
  border: 1px #edf1f5 solid;
  box-sizing: content-box;

  &.disable-add-spot {
    cursor: default;
  }

  &.over-range {
    box-shadow: 0 0 6px rgba(214, 68, 49, 0.55) inset;
    border-color: #d64431;
  }

  .zone {
    color: black;
    left: 271.035px;
    font-size: 20px;
    text-align: center;
    font-weight: bolder;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 2px solid #45505c;
    position: absolute;
    display: block;
    // background-color: #45505c;
    // opacity: 0.9;
    cursor: pointer;
    user-select: none;

    &.resize-top {
      border-top-style: solid !important;
      border-top-color: #d64431 !important;
    }

    &.resize-bottom {
      border-bottom-style: solid !important;
      border-bottom-color: #d64431 !important;
    }

    &.resize-left {
      border-left-style: solid !important;
      border-left-color: #aa3627 !important;
    }

    &.resize-right {
      border-right-style: solid !important;
      border-right-color: #aa3627 !important;
    }

    &.resize-topleft {
      border-top-style: solid !important;
      border-top-color: #aa3627 !important;
      border-left-style: solid !important;
      border-left-color: #aa3627 !important;
    }

    &.resize-bottomleft {
      border-bottom-style: solid !important;
      border-bottom-color: #aa3627 !important;
      border-left-style: solid !important;
      border-left-color: #aa3627 !important;
    }

    &.resize-topright {
      border-top-style: solid !important;
      border-top-color: #aa3627 !important;
      border-right-style: solid !important;
      border-right-color: #aa3627 !important;
    }

    &.resize-bottomright {
      border-bottom-style: solid !important;
      border-bottom-color: #aa3627 !important;
      border-right-style: solid !important;
      border-right-color: #aa3627 !important;
    }

    &:hover,
    &.resize {
      border: 1px dashed #d64431;
      // background-color: #1e77ba;
      // opacity: 0.5;
      border-width: 2px;
    }

    &.move-moving {
      display: none;
    }

    .top,
    .bottom {
      position: absolute;
      left: 0;
      width: 100%;
      height: 20px;
    }

    .top {
      top: -10px;
    }

    .bottom {
      bottom: -10px;
      z-index: 3;
    }

    .left,
    .right {
      position: absolute;
      top: 0;
      height: 100%;
      width: 20px;
    }

    .left {
      left: -10px;
    }

    .right {
      right: -10px;
      z-index: 3;
    }

    .topleft,
    .topright,
    .bottomleft,
    .bottomright {
      position: absolute;
      width: 20px;
      height: 20px;
      z-index: 4;
    }

    .topleft {
      top: -10px;
      left: -10px;
    }

    .topright {
      top: -10px;
      right: -10px;
    }

    .bottomleft {
      bottom: -10px;
      left: -10px;
    }

    .bottomright {
      bottom: -10px;
      right: -10px;
      z-index: 10;
    }
  }

  &.resize-top,
  & .zone .top {
    cursor: n-resize !important;
  }

  &.resize-bottom,
  & .zone .bottom {
    cursor: s-resize !important;
  }

  &.resize-left,
  & .zone .left {
    cursor: w-resize !important;
  }

  &.resize-right,
  & .zone .right {
    cursor: e-resize !important;
  }

  &.resize-topleft,
  & .zone .topleft {
    cursor: nw-resize !important;
  }

  &.resize-topright,
  & .zone .topright {
    cursor: ne-resize !important;
  }

  &.resize-bottomleft,
  & .zone .bottomleft {
    cursor: sw-resize !important;
  }

  &.resize-bottomright,
  & .zone .bottomright {
    cursor: se-resize !important;
  }

  .bg-yellow {
    background-color: #eded3c;
  }
  .bg-light-yellow {
    background-color: #eded3c6c;
  }

  // @media only screen and (min-width: 600px) {
  //   .hoverview {
  //     position: absolute !important;
  //     background-color: white;
  //     color:black !important;
  //     width: 9cm ;
  //     height: 5cm ;
  //     margin-left: 12.6cm ;
  //     opacity: 1 !important;
  //   }
  // }

  @media only screen and (min-width: 0px) {
    .hoverview {
      position: absolute !important;
      width: 6cm;
      color: black !important;
      max-height: 6cm;
      background-color: white;
      margin-left: 90%;
      padding: 10px;
      box-shadow: 3px 4px 13px #4b4545ba;
      z-index: 100;
    }
  }
}
</style>
