<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search v-if="this.$route.query.pending"
                :moduleapi="`${this.baseApi}/pendingvisitCount`" title="Site Visit" :columns="columns"
                routerpath="/crm/addsitevisit" :formOptions="formOptions" :edit="edit" :add="add" :canDelete="canDelete"
                :newPage="true">
                <template #customField='{ props }'>
                  <span v-if="props.column.field == 'lock'">
                    <b-badge :variant="statusVariant(props.row.lock == 0 ? 'Accepted' : 'Pending')
                      ">
                      {{ props.row.lock == 0 ? "Accepted" : "pending" }}
                    </b-badge>
                  </span>
                </template>
              </good-table-column-search>
              <good-table-column-search v-else :moduleapi="`${this.baseApi}/sitevisit`" title="Site Visit"
                :columns="columns" routerpath="/crm/addsitevisit" :formOptions="formOptions" :edit="edit" :add="add"
                :canDelete="canDelete" :newPage="true" viewComponent="siteshow">
                <template #customField='{ props }'>
                  <span v-if="props.column.field == 'lock'">
                    <b-badge :variant="statusVariant(props.row.lock == 0 ? 'Accepted' : 'Pending')
                      ">
                      {{ props.row.lock == 0 ? "Accepted" : "pending" }}
                    </b-badge>
                  </span>
                </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import axios from '@/components/axios';
import { BBadge } from 'bootstrap-vue';

export default {
  components: {
    BBadge,
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: "Source Type",
          field: "sourcetype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Source Type",
          },
        },
        {
          label: "Source",
          field: "source",
          url: 'getUsers',
          responseValue: 'fullname',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Source",
          },
        },
        {
          label: "Manager",
          field: "manager",
          url: 'getUsers',
          responseValue: 'fullname',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search manager",
          },
        },
        {
          label: "Site Visit Assistant",
          field: "assistant",
          url: 'getUsers',
          responseValue: 'fullname',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search assitant",
          },
        },
        {
          label: "Driver",
          field: "driver",
          url: 'getUsers',
          responseValue: 'name',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Driver",
          },
        },
        {
          label: "Client Name",
          field: "clientname",
          url: 'getUsers',
          responseValue: 'fullname',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Client",
          },
        },
        {
          label: "Stay Location",
          field: "stay",
          filterOptions: {
            enabled: true,
            placeholder: "Search Location",
          },
        },
        {
          label: "Vehicle No.",
          field: "vehicleno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Vehicle",
          },
        },
        {
          label: "Arrival Date",
          field: "arrivaldate",
          type: "datetime",

          filterOptions: {
            enabled: true,
            placeholder: "Search date",
          },
        },
        {
          label: "Site Visit Date",
          field: "visitdate",
          type: "datetime",

          filterOptions: {
            enabled: true,
            placeholder: "Search date",
          },
        },
        {
          label: "Departure Date",
          field: "departurdate",
          type: "datetime",

          filterOptions: {
            enabled: true,
            placeholder: "Search date",
          },
        },
        {
          label: "Request",
          field: "lock",
          type: 'dropdown',
          options: [
            { name: 'Accepted', id: 0 },
            { name: 'Pending', id: 1 }
          ],
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: "Search status",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          url: 'getUsers',
          responseValue: 'name',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          url: 'getUsers',
          responseValue: 'name',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Site Visit" : "Add Site Visit"
          }`,
        gridForm: true,
        submitRouterPath: "/crm/sitevisit",
        usersDropdown: true,
        method: "post",
        action: "add",
        getEditValue: `${baseApi}/getsitevisitById`,
        url: `${baseApi}/sitevisit`,
        inputFields: [],
      },
      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Site Visit") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
  beforeMount() {
    this.getDropdowns();
  },
  methods: {
    getDropdowns() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == "dropdown" && item.url) {
          const requestOptionsUsers = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            url: `${baseApi}/${item.url}`,
          };

          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data);
            })
            .catch((error) => console.log(error, "error"));
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
