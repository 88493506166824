<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <label>Enter Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Name" rules="required">
                <b-form-input
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="!this.ifEdit">
            <label>Enter Code</label>
            <label style="color: red !important">*</label>
            <validation-provider #default="{ errors }" name="Code" rules="required">
              <b-form-input v-model="code" name="check-button" placeholder="Enter code">
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <label>Enter Value</label>
            <label style="color: red !important">*</label>
            <validation-provider #default="{ errors }" name="Value" rules="required">
              <b-form-input v-model="value" name="check-button" placeholder="Enter value">
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <div class="pt-2 flex" style="justify-content: center">
          <b-button
            variant="primary"
            type="submit"
            @click.prevent="validationForm"
            :disabled="flag"
          >
            submit
          </b-button>
          <b-button class="ml-2" variant="primary" @click="handleBack"> back </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "@/components/axios";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BButton,
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  components: {
    "v-select": vSelect,
    BCard,
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BButton,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    required,
    BFormCheckbox,
  },
  props: ["closepopup", "getGridData", "selectedID"],
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      name: "",
      code: "",
      value: "",
      flag: false,
      ifEdit: false,
    };
  },
  mounted() {
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      this.$router.push("/");
    }
    this.ifEdit = !!this.selectedID;
    this.ifEdit && this.getEditValue();
    // this.getReminder()
  },
  methods: {
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/settingsById/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.name = item.name;
      this.code = item.code;
      this.value = item.value;
    },
    async validationForm() {
      const data = {
        name: this.name,
        code: this.code,
        value: this.value,
      };
      this.$refs.simpleRules.validate().then(async (success) => {
        if (!success) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.flag = true;

          const requestOptions = {
            method: this.ifEdit ? "PUT" : "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data,
            url: this.ifEdit
              ? `${this.baseApi}/settings/${this.selectedID}`
              : `${this.baseApi}/settings`,
          };
          await axios(requestOptions)
            .then((json) => {
              this.flag = false;

              // let email, name, message;
              // const code = error.toString().includes("409");
              // if (code) {
              //   email = error.response.data.message.original.email
              //     ? error.response.data.message.original.email[0]
              //     : "";
              //   name = error.response.data.message.original.name
              //     ? error.response.data.message.original.name[0]
              //     : "";
              //   message = email + " " + name;
              // }
              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // this.getReminder()
              this.closepopup();
              this.getGridData();
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error.response.data.message}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleBack() {
      this.closepopup();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
