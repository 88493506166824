<style>
.test .dropdown-menu {
  transform: translate3d(-4cm, 1cm, -6px) !important;
  left: -45px !important;
}

tbody td {
  font-size: 13px !important;
}

thead th {
  font-size: 13px !important;
}

.badge-yellow {
  background-color: yellow;
}

.blue-row {
  background-color: #d0e7ff;
}

.gap-1 {
  gap: 10px
}

@media (max-width: 647px) {
  .res {
    width: 100%;
    justify-content: flex-end;
  }
}

@media (max-width: 506px) {
  .resButtons {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .resLead {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .resLines {
    width: 100%;
    float: right;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 600px) {
  .resButton {
    margin-top: 2px;
  }
}

/* .vs__clear {
  display: none;
} */

/* td ul {
  transform: translate3d(-94px, 19px, 0px) !important;
} */

.example::-webkit-scrollbar {
  display: none !important;
}
</style>
<template>
  <div v-if="renderComponent">
    <b-card-code :Addbutton="true" :routerpath="routerpath" :formOptions="formOptions" :visible="visible"
      :selectedID="selectedID" :assignToFormOptions="assignToFormOptions" :getGridData="getGridData">
      <div>
        <b-row>
          <b-col md="12" v-if="title !== 'Legal' && !noAddButton">
            <div class="custom-search d-flex justify-content-between">
              <div>
                <b-button v-if="$route.query.trash" variant="primary" size="sm" class="ml-2 resButton"
                  @click="handleBack">
                  <span class="align-middle">Back</span>
                </b-button>
              </div>
              <b-row>
                <b-form-group :class="plot == true || lead == true || tour == true
                  ? 'resLead'
                  : 'resButtons'
                  ">
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input @input="advanceGlobleSearch" placeholder="Search" type="text"
                      class="d-inline-block" />
                  </div>
                </b-form-group>

                <b-form-group :class="plot == true || lead == true || tour == true
                  ? 'resLead'
                  : 'resButtons'
                  ">
                  <div class="d-flex align-items-center flex-wrap res w-100 justify-content-end">
                    <b-button v-if="multiplepath" variant="success" size="sm" class="ml-2 resButton"
                      @click="handleClickAddMultiple">
                      <feather-icon icon="PlusIcon" class="mr-50" />
                      <span class="align-middle">Add Multiple</span>
                    </b-button>

                    <b-button v-if="plot" variant="success" size="sm" class="ml-2 btn-icon" @click="excelClick()">
                      <feather-icon icon="DownloadIcon" />
                    </b-button>

                    <b-button v-if="lead" variant="success" size="sm" class="ml-2 btn-icon" @click="excelClick()">
                      <feather-icon icon="DownloadIcon" />
                    </b-button>

                    <b-button v-if="lead" variant="success" size="sm" class="ml-2 resButton" @click="handleClickLead">
                      <div style="display: none">
                        <b-form-file id="fileUploadLead" v-model="leadSheetImport" v-if="lead"
                          @input="handleFileChangeLead($event)" />
                      </div>
                      <feather-icon icon="PlusIcon" class="mr-50" />
                      <span class="align-middle">Import Lead</span>
                    </b-button>

                    <b-button v-if="lead" variant="success" size="sm" class="ml-2 resButton"
                      @click="handleClickTransferLead">
                      <span class="align-middle">Transfer Lead</span>
                    </b-button>
                    <b-button v-if="title == 'sales'" variant="success" size="sm" class="ml-2 resButton"
                      @click="handleClickTransferLead">
                      <span class="align-middle">Transfer Lead</span>
                    </b-button>
                    <b-button v-if="tour" variant="secondary" size="sm" class="ml-2 resButton"
                      @click="handleClickAddTour">
                      <feather-icon icon="PlusIcon" class="mr-50" />
                      <span class="align-middle">Add Tour</span>
                    </b-button>
                    <b-button v-if="plot" variant="success" size="sm" class="ml-2 resButton" @click="handleClickPlot">
                      <div style="display: none">
                        <b-form-file id="fileUpload" v-if="plot" @input="handleFileChange($event)" />
                      </div>
                      <feather-icon icon="PlusIcon" class="mr-50" />
                      <span class="align-middle">Import Plot</span>
                    </b-button>
                    <b-button v-if="kyc !== true" variant="primary" size="sm" class="ml-2 resButton"
                      @click="handleClickAddbutton">
                      <feather-icon icon="PlusIcon" class="mr-50" />
                      <span class="align-middle">Add New</span>
                    </b-button>

                    <b-button v-if="bulksales == true" variant="success" size="sm" class="ml-2 resButton"
                      @click="handleClickBulkSales">
                      <feather-icon icon="PlusIcon" class="mr-50" />
                      <span class="align-middle">Bulk Add</span>
                    </b-button>
                    <b-button v-if="title == 'Daily Working'" variant="success" size="sm" class="ml-2 resButton"
                      @click="$router.push('/reports/hr/workingreport')">
                      <span class="align-middle">Working Report</span>
                    </b-button>
                    <b-button variant="danger" size="sm" class="ml-1 resButton" id="multidelete"
                      @click="handleMultiDelete" v-if="
                        (canDelete == 1 || userRole == 'admin') &&
                        !noDelete &&
                        title !== 'Legal' &&
                        title !== 'Issue Log' &&
                        title !== 'Modules' &&
                        title !== 'Softwares'
                      ">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor"
                        class="bi bi-trash3-fill" viewBox="0 0 16 16">
                        <path
                          d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                      </svg>
                    </b-button>

                    <b-tooltip target="multidelete" triggers="hover">
                      Multi Delete
                    </b-tooltip>
                    <b-button size="sm" variant="success" class="ml-1 resButton" id="restore"
                      @click="handleMultiRestore" v-if="userRole == 'admin' && $route.query.trash">
                      <feather-icon icon="CornerUpLeftIcon" />
                    </b-button>
                    <b-tooltip target="restore" triggers="hover">
                      Multi Restore
                    </b-tooltip>
                    <b-button variant="danger" size="sm" class="ml-1 resButton" id="multidelete"
                      @click="handleTrashRoute" v-if="userRole == 'admin' && !$route.query.trash">
                      <div class="d-flex align-items-center gap-1">
                        <span>Trash</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor"
                          class="bi bi-trash3-fill" viewBox="0 0 16 16">
                          <path
                            d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                        </svg>
                      </div>
                    </b-button>


                    <span class="ml-2 test resLines">
                      <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
                        <template v-slot:button-content>
                          <feather-icon width="30" height="30" icon="AlignRightIcon" size="16"
                            class="text-body align-middle mr-25" />
                        </template>
                        <b-dropdown-item @click="downloadExcel('All')" variant="warning" v-if="sheetApi">
                          <b>
                            <feather-icon icon="ArrowDownIcon" class="mr-50" />
                            Download All Data
                          </b>
                        </b-dropdown-item>
                        <b-dropdown-item @click="downloadExcel('')" variant="warning">
                          <b>
                            <feather-icon icon="ArrowDownIcon" class="mr-50" />
                            Download This Page Data
                          </b>
                        </b-dropdown-item>
                        <div class="example" style="
                            width: 6cm;
                            height: 7cm;
                            overflow: hidden scroll;
                            padding: 6px 20px;
                          ">
                          <b-form-group>
                            <label class="h5"> Select Field </label>
                            <b-form-checkbox-group id="checkbox-group-1" v-model="columnFilter" @input="isFilterColumn"
                              :options="columnsData" name="field" class="demo-inline-spacing" />
                          </b-form-group>
                        </div>
                      </b-dropdown>
                    </span>
                    <b-modal id="modal-form" v-model="visible" size="sm" cancel-variant="outline-secondary"
                      ok-title="Submit" cancel-title="Close" :title="showPassword
                        ? 'Change password'
                        : selectedID
                          ? formOptions.formTitle.replace('Add', 'Edit')
                          : formOptions.formTitle
                        " no-close-on-backdrop hide-footer>
                      <Form :popUp="true" :options="formOptions" :handleCloseModel="handleCloseModel"
                        :selectedID="selectedID" :getGridData="getGridData" :edit="edit" :add="add"></Form>
                    </b-modal>
                    <b-modal id="modal-form" v-model="staticPopUp" :title="title" size="sm"
                      cancel-variant="outline-secondary" ok-title="Submit" cancel-title="Close" no-close-on-backdrop
                      hide-footer>
                      <component :is="popUpCompnent" :closepopup="closepopup" :getGridData="getGridData"
                        :selectedID="selectedID" />
                    </b-modal>
                    <b-modal id="modal-form" v-model="transferClick" title="Transfer Lead" size="lg"
                      cancel-variant="outline-secondary" ok-title="Submit" cancel-title="Close" no-close-on-backdrop
                      hide-footer>
                      <TransferLead :closepopup="closepopup" />
                    </b-modal>

                    <b-modal id="modal-form-view" v-model="viewPopUp" :title="title" size="lg"
                      cancel-variant="outline-secondary" ok-title="Submit" cancel-title="Close" no-close-on-backdrop
                      hide-footer>
                      <component :is="viewComponent" :closepopup="closepopup" :selectedID="selectedID"
                        v-if="viewComponent" />
                      <viewPage v-else :closepopup="closepopup" :moduleapi="moduleapi" :title="title"
                        :viewFields="viewFields" :getEditValue="formOptions.getEditValue" :selectedID="selectedID">
                        <template #extraView="{ data, item }">
                          <slot name="extraView" :data="data" :item="item"> </slot>
                        </template>
                      </viewPage>
                    </b-modal>
                  </div>
                </b-form-group>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- </div> -->

      <!-- table -->
      <vue-good-table class="table" :row-style-class="useRowColors ? rowClassCallback : null" :columns="columnsItem"
        :rows="rows" :rtl="direction" @on-row-dblclick="handleEdit" :isLoading="loadData" :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false,
          selectAllByGroup: false,
        }" @on-selected-rows-change="selectionChanged($event)" :line-numbers="true" :search-options="{
          externalQuery: searchTerm,
        }" :pagination-options="pagination">
        <!-- <div slot="table-actions">
        This will show up on the top right of the table.
      </div> -->
        <template slot="column-filter" slot-scope="props">
          <v-select :options="props.column.url ? data[props.column.url] : props.column.options" v-if="
            props.column.url !== 'getUsers' &&
            props.column.type &&
            (props.column.type == 'dropdown' || props.column.type == 'staticdropdown')
          " :label="props.column.responseValue" multiple @input="(valuesArray) =>
            handleCustomFilter(
              valuesArray,
              props.column.field,
              'dropdown',
              props.column.searchField
            )
            ">
            <template #option="item">
              <div v-if="props.column.slotPrams && item[props.column.slotPrams]">
                <span>{{ item[props.column.responseValue] }} ({{ item[props.column.slotPrams] }})</span>
              </div>
            </template>

          </v-select>

          <v-select :options="data[props.column.url]" v-else-if="props.column.url == 'getUsers'"
            style="min-width: 180px" :label="props.column.responseValue" multiple @input="(valuesArray) =>
              handleCustomFilter(
                valuesArray,
                props.column.field,
                'dropdown',
                props.column.searchField
              )
              ">
            <template #option="{ name, profile_image, username, surname }">
              <b-avatar :src="getprofileImage(profile_image)" v-if="name !== 'All'" />
              <span class="font-weight-bolder"> {{ name }} {{ surname }}</span><span v-if="name !== 'All'">({{ username
                }})</span>
            </template>
          </v-select>
          <flat-pickr v-else-if="
            props.column.type == 'datetime' ||
            props.column.field == 'created_date' ||
            props.column.field == 'updated_date'
          " v-model="props.column.value" placeholder="Select Date" class="form-control" :config="{
            mode: 'range',
            enableTime: false,
            dateFormat: 'd/m/Y',
          }" @input="
            handleCustomFilter($event, props.column.field, 'date', props.column.field)
            " style="background: transparent" />
          <b-form-input v-else @input="
            handleCustomFilter(
              $event,
              props.column.field,
              'textbox',
              props.column.searchField
            )
            " placeholder="Search" type="text" class="d-inline-block" />
        </template>
        <template slot="loadingContent">
          <b-spinner variant="primary" label="Text Centered" />
        </template>
        <template slot="table-row" slot-scope="props">
          <slot name="allCustomFields" :props="props" :getGridData="getGridData">
            <div>
              <div v-if="props.column.type === 'image'"
                class="text-nowrap d-flex justify-content-center cursor-pointer">
                <div :id="'image' + props.row.id">
                  <b-avatar :src="getprofileImage(props.row[props.column.field])" />
                </div>
                <b-tooltip v-if="props.row[props.column.field]" :target="'image' + props.row.id" variant="light"
                  triggers="hover">
                  <img :src="getprofileImage(props.row[props.column.field])" alt="" height="100" style="width: 100%" />
                </b-tooltip>
              </div>
              <span v-else-if="props.column.type === 'datetime'">
                {{ setDate(props.row[props.column.field], props.column) }}
              </span>
              <span v-else-if="props.column.field === 'created_date'">
                {{
                  moment(
                    props.row.created_at ? props.row.created_at : props.row.enterdatetime
                  ).format("DD/MM/YYYY h:m A")
                }}
              </span>
              <span v-else-if="props.column.field === 'updated_date'">
                {{
                  moment(
                    props.row.updated_at
                      ? props.row.updated_at
                      : props.row.modifieddatetime
                  ).format("DD/MM/YYYY h:m A")
                }}
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status' && !changeStatus[props.row.id]" class="cursor-pointer"
                @click="clickStatusChage(props.row, title)">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'approved1'">
                <b-badge :variant="statusVariant(props.row.approved1)">
                  {{ props.row.approved1 ? props.row.approved1 : "Pending" }}
                </b-badge>
              </span>
              <div v-else-if="
                props.column.field === 'status' &&
                title == 'Plot' &&
                changeStatus[props.row.id]
              ">
                <v-select v-model="props.row.status" label="name" style="width: 120px"
                  :options="['Vacant', 'Booked', 'Sold', 'Hold', 'Registered']"
                  @input="handleStatusChage(props.row.status, props.row.id)" />
              </div>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <div v-if="$route.query.trash" style="width: fit-content; display: flex;gap:10px">
                  <b-badge variant="danger" :id="'delete_permanent' + props.row.id" class="cursor-pointer"
                    @click="handleDelete(props.row)">
                    <feather-icon icon="TrashIcon" />
                  </b-badge>
                  <b-tooltip :target="'delete_permanent' + props.row.id" triggers="hover">
                    Permanent Delete
                  </b-tooltip>
                  <b-badge variant="success" :id="'restore' + props.row.id" class="cursor-pointer"
                    @click="restore([props.row.id])">
                    <feather-icon icon="CornerUpLeftIcon" />
                  </b-badge>
                  <b-tooltip :target="'restore' + props.row.id" triggers="hover">
                    Restore
                  </b-tooltip>
                </div>
                <div v-else style="width: fit-content; display: flex">
                  <slot name="action" :row="props.row" :getGridData="getGridData"></slot>
                  <b-badge v-if="
                    userRole == 'admin' &&
                    !noAction &&
                    (title == 'Employee' ||
                      title == 'Channel Partner' ||
                      title == 'Franchise')
                  " variant="info" :id="'user' + props.row.id" class="mr-50 cursor-pointer"
                    @click="handleUserLogin(props.row)">
                    <feather-icon icon="UserIcon" />
                  </b-badge>
                  <b-tooltip v-if="
                    userRole == 'admin' &&
                    !noAction &&
                    (title == 'Employee' ||
                      title == 'Channel Partner' ||
                      title == 'Franchise')
                  " :target="'user' + props.row.id" triggers="hover">
                    Log in as {{ props.row.name }}
                  </b-tooltip>
                  <b-badge v-if="
                    (edit == 1 || userRole == 'admin') &&
                    !noAction &&
                    title !== 'Issue Log' &&
                    (title != 'Task' ||
                      userRole == 'admin' ||
                      props.row.enterby_data[0].id == userData.id)
                  " variant="warning" :id="'edit' + props.row.id" class="mr-50 cursor-pointer"
                    @click="handleEdit(props.row)">
                    <feather-icon icon="Edit2Icon" />
                  </b-badge>
                  <b-tooltip v-if="
                    (edit == 1 || userRole == 'admin') &&
                    !noAction &&
                    title !== 'Issue Log' &&
                    (title != 'Task' ||
                      userRole == 'admin' ||
                      props.row.enterby_data[0].id == userData.id)
                  " :target="'edit' + props.row.id" triggers="hover">
                    Edit
                  </b-tooltip>
                  <b-badge v-if="title !== 'Issue Log' && !noAction" variant="warning" :id="'view' + props.row.id"
                    class="mr-50 cursor-pointer" @click="handleView(props.row)">
                    <feather-icon icon="EyeIcon" />
                  </b-badge>
                  <b-tooltip v-if="title !== 'Issue Log' && !noAction" :target="'view' + props.row.id" triggers="hover">
                    View
                  </b-tooltip>

                  <b-badge variant="success" :id="'password' + props.row.id" v-if="showPassword"
                    class="mr-50 cursor-pointer" @click="handlePassword(props.row)">
                    <feather-icon icon="LockIcon" />
                  </b-badge>
                  <b-tooltip v-if="showPassword" :target="'password' + props.row.id" triggers="hover">
                    Change password
                  </b-tooltip>

                  <b-badge v-if="
                    (canDelete == 1 || userRole == 'admin') &&
                    !noAction &&
                    title != 'Issue Log' &&
                    (title != 'Task' ||
                      userRole == 'admin' ||
                      props.row.enterby_data[0].id == userData.id)
                  " variant="danger" :id="'delete' + props.row.id" class="cursor-pointer"
                    @click="handleDelete(props.row)">
                    <feather-icon icon="TrashIcon" />
                  </b-badge>
                  <b-tooltip v-if="
                    (canDelete == 1 || userRole == 'admin') &&
                    !noAction &&
                    title != 'Issue Log' &&
                    (title != 'Task' ||
                      userRole == 'admin' ||
                      props.row.enterby_data[0].id == userData.id)
                  " :target="'delete' + props.row.id" triggers="hover">
                    Delete
                  </b-tooltip>
                </div>

              </span>

              <!-- Column: Common -->

              <span v-else>
                <slot name="customField" :props="props" :getGridData="getGridData">
                  <span v-if="
                    props.column.type == 'staticdropdown' ||
                    (props.column.url && props.column.type == 'dropdown')
                  ">
                    <div v-if="
                      Array.isArray(props.row[props.column.field]) &&
                      props.row[props.column.field].length > 0
                    ">
                      {{ props.row[props.column.field][0][props.column.responseValue] }}
                    </div>
                    <div v-else-if="typeof props.row[props.column.field] == 'string'">
                      {{ props.row[props.column.field] }}
                    </div>
                    <div v-else-if="props.row[props.column.field]">
                      {{ props.row[props.column.field][props.column.responseValue] }}
                    </div>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </slot>
              </span>
            </div>
          </slot>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <b-form-select v-model="pageLength"
                :options="['5', '10', '20', '50', '100', '500', '1000', '5000', '10000']" class="mx-1"
                @input="changePagination()" />
              <span class="text-nowrap">
                Showing {{ paginationStart }} to {{ paginationEnd }} of
                {{ paginationTotal }}</span>
            </div>
            <div>
              <b-pagination v-model="paginationValue" :total-rows="paginationTotal" :per-page="pageLength" first-number
                last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="changePagination('change')">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <div id="element-to-convert" style="display: none">
        <div class="card">
          <div class="card-body mx-4">
            <div class="container">
              <table></table>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="modal-form" v-model="remarksPopUp" cancel-variant="outline-secondary" ok-title="Submit"
        cancel-title="Close" title="Remarks" hide-footer>
        <b-form-textarea v-model="remarksForUpdate" placeholder="Enter Remarks" rows="5" />
        <b-button variant="primary" @click.prevent="submitRemarks($event)" class="mt-2">
          Submit
        </b-button>
      </b-modal>
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import printComp from "../views/print/printReceipt.vue";
import approvalStatic from "../views/setup/approval/ApprovalStatic.vue";
import reminderStatic from "../views/setup/reminder/ReminderStatic.vue";
import addTemplate from "../views/setup/template/AddTemplate.vue";
import TransferLead from "../views/crm/lead/TransferLead.vue";
import AddRequest from "@/views/hr/hrrequest/AddRequest.vue";
import receiptShow from "../views/accounting/receipt/Show.vue";
import vSelect from "vue-select";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";
import jsPDF from "jspdf";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import viewPage from "@/components/viewPage.vue";
import salesshow from "../views/crm/sales/show.vue";
import siteshow from "../views/crm/sitevisit/Show.vue";
import landshow from "../views/crm/landsales/Show.vue";
import OtherPurchaseShow from "../views/accounting/otherpurchase/Show";
import LeadShow from "../views/crm/lead/Show.vue";
import PlotShow from "../views/master/crm/plot/Show.vue";
import LandPurchase from "../views/accounting/landpurchase/Show.vue";
import LandShowView from "../views/master/crm/land/Show.vue";
import ProjectShow from "../views/master/crm/project/Show.vue";
import flatPickr from "vue-flatpickr-component";
import AddSettings from "@/views/setup/settings/AddSettings.vue";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormSelect,
  BDropdown,
  BCol,
  BRow,
  BButton,
  BDropdownItem,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTooltip,
  BSpinner,
  BFormTextarea,
  BImg,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import html2pdf from "html2pdf.js";
import salesDeed from "./salesDeed";
import * as XLSX from "xlsx";

// import { codeColumnSearch } from "./code";

export default {
  props: [
    "newPage",
    "moduleapi",
    "title",
    "addTourPath",
    "columns",
    "routerpath",
    "formOptions",
    "assignToFormOptions",
    "paging",
    "addPlot",
    "multiplepath",
    "approval",
    "reminder",
    "template",
    "lead",
    "add",
    "edit",
    "canDelete",
    "canView",
    "kyc",
    "bulksales",
    "viewFields",
    "popUpCompnent",
    "sheetApi",
    "viewComponent",
    "noAddButton",
    "noDelete",
    "noAction",
    "defaultParams",
    "useRowColors",
  ],
  components: {
    AddSettings,
    AddRequest,
    viewPage,
    salesshow,
    siteshow,
    LeadShow,
    flatPickr,
    addTemplate,
    TransferLead,
    reminderStatic,
    landshow,
    LandShowView,
    OtherPurchaseShow,
    approvalStatic,
    BFormTextarea,
    LandPurchase,
    BCardCode,
    BCol,
    BRow,
    html2pdf,
    vSelect,
    BFormFile,
    salesDeed,
    approvalStatic,
    VueGoodTable,
    BSpinner,
    VueHtml2pdf,
    BFormCheckboxGroup,
    printComp,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    PlotShow,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    Form,
    BTooltip,
    receiptShow,
    ProjectShow,
    BImg,
  },
  data(props) {
    return {
      props,
      moment,
      imagePath: process.env.VUE_APP_IMAGE_PATH,

      data: [],
      remarksForUpdate: "",
      tableKey: 0,
      paginationValue: 1,
      paginationTotal: 0,
      loadData: false,
      visible: false,
      passwordVisible: false,
      tour: false,
      plot: false,
      plotData: "",
      PassWordField: {
        inputFields: [],
      },
      pageLength: 50,
      columnFilter: [],
      dir: false,
      showPassword: false,
      CPEditRights: false,
      CPDeleteRights: false,
      receiptData: "",
      paginationStart: 0,
      paginationEnd: 0,
      FranchiseEditRights: false,
      CityOption: [],
      FranchiseDeleteRights: false,
      EditField: {
        inputFields: [],
      },
      pagination: {
        enabled: true,
        mode: "records",
        perPage: 100000,
        position: "bottom",
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        setCurrentPage: 2,
        jumpFirstOrLast: true,
        firstLabel: "First Page",
        lastLabel: "Last Page",
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All",
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      },
      date: "",
      multidelete: false,
      selectedID: 0,
      rowSelection1: [],
      rowSelection: [],
      renderComponent: false,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      webEndpoint: process.env.VUE_APP_WEBENDPOINT,

      accessToken: localStorage.getItem("accessToken"), // JSON.parse()
      users: [],
      rows: [],
      columnsData: [],
      searchTerm: null,
      EDrights: false,
      isFilterSwitch: false,
      approvalPopUp: false,
      reminderPopUp: false,
      templatePopUp: false,
      salesdeedPopup: false,
      // stateOptions: [],
      transferClick: false,
      userRole: "",
      // enabledisable: [],
      columnsItem: "",
      projectOptions: [],
      showReceiptsModal: false,
      receipts: [],
      changeStatus: false,
      changeStatus: {},
      selectedPlot: {},
      searchData: {},
      viewPopUp: false,
      remarksPopUp: false,
      KycUserId: "",
      params: {},
      staticPopUp: false,
      leadSheetImport: null,
      oldStatus: "",
      newStatus: "",
      plotId: "",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    setDate() {
      return (date, column) => {
        if (date && moment(date.substring(0, 10), 'DD/MM/YYYY', true).isValid()) {
          return date.substring(0, 10)
        }
        if (date == '0000-00-00') {
          return '-'
        }
        if (
          date &&
          (column.field == "modifieddatetime" ||
            column.field == "enterdatetime" ||
            column.field == "created_at" ||
            column.field == "updated_at" ||
            column.time)
        ) {
          return moment(date).format("DD/MM/YYYY h:m A");
        } else if (date) {
          return moment(date).format("DD/MM/YYYY");
        } else {
          return date;
        }
      };
    },
  },
  async mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.userRole = this.userData.role;
    this.params = {};
    Object.keys(this.$route.query).map((item) => {
      this.params[item] = this.$route.query[item];
    });
    if (this.addTourPath) {
      this.tour = true;
    }
    if (this.addPlot) {
      this.plot = true;
    }
    this.columnsItem = this.columns;
    this.columnsItem.map((item) => {
      const data = {
        text: item.label,
        value: item,
      };
      this.columnsData.push(data);
    });
    this.columnFilter = this.columnsItem;
    this.columnsItem = this.columnFilter;
    this.forceRerender();
    this.EditField.inputFields = this.formOptions.inputFields;
    this.passwordFieldHandel();
    this.formOptions.inputFields.map((item) => {
      if (item.type == "password") {
        this.showPassword = true;
      }
    });
    this.users = this.$store.state.app.all_users;
    await this.getGridData();
    this.getAllDropdown();
    // this.getState();
    // if (this.title == "Plot") {
    //   this.getProject();
    // }
  },
  created() {
    // this.$http.get("/good-table/basic").then((res) => {
    //   this.rows = res.data;
    // });
  },
  methods: {
    rowClassCallback(row) {
      if (row.is_kyc) {
        return "blue-row";
      }
      return "";
    },
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    handleCustomFilter(e, column, type, searchField) {
      if (type == "textbox") {
        this.searchData[searchField ? searchField : column] = e;
        if (!e) {
          delete this.searchData[searchField ? searchField : column];
        }
      } else if (type == "date") {
        if (e) {
          let dateArr = e.split(" to ");
          dateArr = dateArr.map((item) => {
            return moment(item, "DD/MM/YYYY").format("YYYY-MM-DD");
          });
          this.searchData[searchField ? searchField : column] = dateArr.join("to");
        }
        if (!e) {
          delete this.searchData[searchField ? searchField : column];
        }
      } else {
        let arr = [];
        e.map((item) => {
          arr.push(item.id);
        });
        this.searchData[searchField ? searchField : column] = arr.join(",");
        if (e.length == 0) {
          delete this.searchData[searchField ? searchField : column];
        }
      }
      this.params = [];
      Object.keys(this.$route.query).map((item) => {
        this.params[item] = this.$route.query[item];
      });
      for (let key in this.searchData) {
        this.params[key] = this.searchData[key];
      }
      this.debounceInput();
    },
    debounceInput: _.debounce(function () {
      this.getGridData();
    }, 1000),
    async handleUserLogin(user) {
      await axios({
        method: "POST",
        url: `${this.baseApi}/userLoginById/${user.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }).then((json) => {
        localStorage.setItem("accessToken", json.data.token);
        localStorage.setItem("userData", JSON.stringify(json.data.user));
        localStorage.setItem("refreshToken", json.data.token);

        this.$router.push("/").then(() => {
          window.location.reload();
        });
      });
    },
    handleMultiMessage() {
      // this.multidelete=true
      this.rowSelection = [];
      this.rowSelection1.map((item) => {
        this.rowSelection.push(item.mobile);
      });
    },
    clickStatusChage(row, title) {
      if (title == "Plot") {
        this.changeStatus[row.id] = true;
        this.oldStatus = row.status;
        this.plotId = row.id;
      }
      this.$forceUpdate();
    },
    handleTrashRoute() {
      const current = window.location.href
      const url = new URL(current);
      url.searchParams.set('trash', 1)
      window.location.href = url.toString()

    },
    async handleStatusChage(status, id) {
      this.newStatus = status;
      if (
        this.oldStatus &&
        (this.oldStatus.toLowerCase() == "booked" ||
          this.oldStatus.toLowerCase() == "sold" ||
          this.oldStatus.toLowerCase() == "registered")
      ) {
        if (status.toLowerCase() == "vacant" || status.toLowerCase() == "hold") {
          this.$swal({
            title: "Are you sure?",
            text: `This plot is already ${this.oldStatus} do you want to change status to ${status}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, update it!",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.handleStatus();
            }
          });
        } else {
          this.handleStatus();
        }
      } else {
        this.handleStatus();
      }
    },
    async submitRemarks() {
      await axios({
        method: "POST",
        url: `${this.baseApi}/plot/change-status`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify({
          id: this.plotId,
          status: this.newStatus,
          remarks: this.remarksForUpdate,
        }),
      }).then((json) => {
        this.remarksPopUp = false;
        this.remarksForUpdate = "";
        this.changeStatus[this.plotId] = false;
        this.getGridData();
        this.$forceUpdate();
      });
    },
    async handleStatus() {
      this.remarksPopUp = true;
    },
    handleClickBulkSales() {
      this.$router.push("/crm/multisales/addsales");
    },
    handleMultiDelete() {
      let ids = [];
      this.rowSelection.map((item) => {
        ids.push(item.id);
      });
      if (ids.length == 0) {
        this.$swal({
          icon: "warning",
          title: "Warning",
          text: "Please select atleast one",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            let url = this.$route.query.trash ? `${this.moduleapi}-permanent` : this.moduleapi
            await axios({
              method: "delete",
              url,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              data: JSON.stringify({
                ids,
              }),
            }).then((json) => {
              // this.$router.push(this.options.submitRouterPath);
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your data has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.getGridData(this.params);
            });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.multidelete = false;
        });
    },
    async handleMultiRestore() {
      let ids = [];
      this.rowSelection.map((item) => {
        ids.push(item.id);
      });
      if (ids.length == 0) {
        this.$swal({
          icon: "warning",
          title: "Warning",
          text: "Please select atleast one",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      this.restore(ids)
    },
    selectionChanged(params) {

      // this.multidelete = true;
      this.rowSelection = params.selectedRows;

      // if (params.selectedRows.length == 0) {
      //   this.multidelete = false;
      // }
      // console.log(params.selectedRows.length)
    },
    async downloadExcel(e) {
      if (e == "All") {
        await axios({
          method: "GET",
          url: `${this.baseApi}/${sheetApi}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
        }).then((res) => {
          let fileURL =
            process.env.VUE_APP_IMAGE_PATH +
            res?.data["export-url"]?.url.split("/ng-erp/")[1];
          const fURL = document.createElement("a");
          fURL.href = fileURL;
          fURL.setAttribute("download", `Lead.xlsx`);
          document.body.appendChild(fURL);
          document.body.appendChild(fURL);
          fURL.click();
        });
      } else {
        let data = [];
        this.rows.map((item, index) => {
          data[index] = {};
          this.columnsItem.map((inner) => {
            if (inner.type == "dropdown" || inner.type == "staticdropdown") {
              if (Array.isArray(item[inner.field])) {
                data[index][inner.label] =
                  item[inner.field].length > 0
                    ? item[inner.field][0][inner.responseValue]
                    : "";
              } else if (item[inner.field]) {
                data[index][inner.label] = item[inner.field][inner.responseValue];
              } else {
                data[index][inner.label] = "";
              }
            } else if (inner.field !== "action") {
              data[index][inner.label] = item[inner.field];
            }
          });
        });
        data.unshift({});
        const data11 = XLSX.utils.json_to_sheet(data, this.columnsItem);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data11, "data");
        XLSX.writeFile(wb, `${this.title}.xlsx`);
      }
    },
    salesDeedClicked(row) {
      this.selectedID = row.id;
      this.salesdeedPopup = true;
      this.selectedPlot = row;
    },
    advanceGlobleSearch(val) {
      this.searchTerm = val;
    },
    clearFilter() {
      this.searchTerm = null;
    },
    async advanceSearch(val, DropDown) {
      this.searchTerm = val;
      if (DropDown == "project") {
        this.searchTerm = val.name;
      }
      if (DropDown == "state") {
        const reportauthority = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getCityByState`,
          data: {
            State: val,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.CityOption = response.data.data;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    searchFieldWise(field, value) { },
    closepopup() {
      this.staticPopUp = false;
      this.transferClick = false;
      this.salesdeedPopup = false;
      this.viewPopUp = false;
      this.selectedID = null;
      this.remarksForUpdate = null;
      this.getGridData();
    },
    handleClickApproval() {
      this.selectedID = 0;
      this.approvalPopUp = true;
    },
    handleClickReminder() {
      this.selectedID = 0;
      if (this.reminder == true) {
        this.reminderPopUp = true;
      } else if (this.template == true) {
        this.templatePopUp = true;
      }
    },
    isFilterColumn() {
      this.columnsItem = [];
      const data = this.columnFilter;
      this.columnFilter = [];
      data.map((item) => {
        if (item.field !== "action") {
          this.columnFilter.push(item);
        }
      });
      data.map((item) => {
        if (item.field == "action") {
          this.columnFilter.push(item);
        }
      });
      this.columnsItem = this.columnFilter;
    },
    setDropdownValue() {
      //   console.log(this.props._props.formOptions.inputFields, 'test--')
      //   if (this.props.columns) {
      //   }
    },
    passwordFieldHandel() {
      this.formOptions.inputFields.map((item) => {
        // console.log(item.type, "item---");
        if (item.type == "password") {
          this.PassWordField.inputFields.push(item);
        }
      });

      // console.log(this.formOptions.inputFields, "item--");
      // PassWordField.push(formOptions.)
    },
    handleCloseModel() {
      // console.log("first");
      this.visible = false;
    },
    // handleClosePasswordModel() {
    //   this.passwordVisible = false;
    // },
    handleClickAddTour() {
      this.$router.push(`${this.addTourPath}`);
    },
    handleClickAddMultiple() {
      this.$router.push(`${this.multiplepath}`);
    },
    handleClickPlot() {
      document.getElementById("fileUpload").click();
    },
    handleClickLead() {
      document.getElementById("fileUploadLead").click();
    },
    async handleFileChange(e) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("csv", e);
      formData.append("type", "testing");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/plotAttachment`,
      };

      await axios(requestOptions)
        .then((json) => {
          this.$swal({
            title: "Submited",
            text: json.data.message
              ? `${json.data.message}`
              : json.data.success
                ? `${json.data.success}`
                : "Update SuccessFully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getGridData();
        })
        .catch((error) => {
          this.$swal({
            title: "Error !",
            text: "Something went wrong , Please check your file",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    async handleFileChangeLead(e) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("csv", e);
      formData.append("type", "testing");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/bulklead`,
      };

      await axios(requestOptions)
        .then((json) => {
          let msg = "Imported Successfully";
          // this.leadSheetImport = null
          let emails = "";
          if (json.data.existing_emails) {
            emails = [...new Set(json.data.existing_emails)];
          }
          msg +=
            emails.length > 0
              ? " <br/><b>existing emails: </b><br/>" + emails.join(",")
              : "";
          this.$swal({
            title: "Submited",
            html: msg,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getGridData();

          // window.location.reload()
        })
        .catch((error) => {
          this.$swal({
            title: "Error !",
            text: "Some error occurred",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          console.log(error, "error");
        })
        .finally(() => { });
    },
    handleClickTransferLead() {
      this.transferClick = true;
    },
    onProgress(event) { },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    handelShowModal(receipt) {
      this.showReceiptsModal = true;
      this.receipts = receipt.receipt_items;
    },
    handleBack() {
      const url = new URL(window.location.href)
      const params = new URLSearchParams(url.search);
      params.delete('trash');
      url.search = params.toString();
      window.location.href = url
    },
    async handelRecieptPDF(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getreceiptById/${id}`,
      };

      await axios(requestOptions)
        .then((json) => {
          this.receiptData = json.data.data[0];
          let year = moment(this.receiptData.created_at).year();
          let id = this.receiptData.id.toString().padStart(5, "0");
          this.receiptData.receiptNo = "NGR-" + year + "-" + id;

          if (this.receiptData && this.receiptData.receipt_items.length > 30) {
            this.$swal({
              title: "Denied",
              text: "Cannot generate receipt which contains more than 30 plot payments",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.receiptData.paymentDetails = "";
            if (
              parseFloat(this.receiptData?.receipt_items[0]?.remaining) +
              parseFloat(this.receiptData?.receipt_items[0]?.amount) ==
              parseFloat(this.receiptData.receipt_items[0].sales_data.totalpayment)
            ) {
              this.receiptData.paymenttype = this.receiptData?.receipt_items[0].sales_data.paymenttype;
            } else if (
              this.receiptData?.receipt_items[0].sales_data.bookingtype == "EMI"
            ) {
              this.receiptData.paymenttype = "EMI";
            } else {
              this.receiptData.paymenttype = "Part Payment";
            }
            this.$refs.html2Pdf.generatePdf();
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    handleClickAddbutton() {
      if (JSON.stringify(this.formOptions.inputfiels) == "[]") {
        this.formOptions.inputFields = this.editData;
      } else {
        this.formOptions.inputFields = this.EditField.inputFields;
      }

      // this.$eventBus.$emit("onMounted", this.formOptions);
      if (this.newPage == true) {
        this.$router.push(`${this.routerpath}`);
      }
      if (!this.newPage) {
        let countFields = 0;
        this.formOptions.inputFields &&
          this.formOptions.inputFields.map((item) => {
            if (item.type != "hidden") {
              countFields = countFields + 1;
            }
          });
        if (countFields < 10 || this.popUpCompnent) {
          if (this.popUpCompnent) {
            this.staticPopUp = true;
          } else {
            this.visible = true;
          }
        } else {
          this.$router.push(`${this.routerpath}`);
        }
      }

      if (this.add !== 1 && this.userRole !== "admin") {
        this.visible = false;
      }
      this.forceRerender();
      this.selectedID = 0;
    },
    changePagination() {
      for (let key in this.searchData) {
        if (this.searchData[key]) {
          this.params[key] = this.searchData[key];
        }
      }
      this.getGridData();
    },
    getGridData() {
      this.loadData = true;
      let paramsData = {
        pagenum: this.paginationValue,
        pagesize: this.pageLength,
        ...this.params,
      };
      if (this.defaultParams) {
        paramsData = { ...paramsData, ...this.defaultParams };
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: paramsData,
      };
      const queryString = new URLSearchParams(paramsData).toString();
      const url = `${this.moduleapi}?${queryString}`;

      fetch(url, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          this.paginationTotal = json.data.total;
          this.paginationStart = json.data.from;
          this.paginationEnd = json.data.to;
          if (json.data) {
            this.rows = [];
            this.rows = json.data;
          }
          if (json.data.data) {
            this.rows = [];
            this.rows = json.data.data;
          }
          if (json.data?.first_page_url?.includes("target")) {
            this.rows = [];
            json.data.data.map((item) => {
              item.targetItem.map((data) => {
                data.enterby = item.enterby;
                data.modifiedby = item.modifiedby;
                const data1 = {
                  updated_at: item.updated_at,
                  created_at: item.created_at,
                  year: item.year,
                  ...data,
                };
                this.rows.push(data1);
              });
            });
          }
          if (this.title == "Customer" || this.title == "Lead") {
            this.rows.map((item) => {
              if (item.leadsource.length) {
                if (
                  item.leadsource[0].name == "Employee" ||
                  item.leadsource[0].name == "Channel Partner" ||
                  item.leadsource[0].name == "Franchise" ||
                  item.leadsource[0].name == "Self"
                ) {
                  this.users.map((innerItem) => {
                    if (item.subsource == innerItem.id) {
                      item.subsource = innerItem;
                    }
                  });
                } else if (item.leadsource[0].name == "Campaign") {
                  item.subsource = item.campaign;
                }
              }
            });
          }
          if (this.title == "Project") {
            this.rows.map((item) => {
              item.assigntoRole = "";
              if (item.assignto.length > 0) {
                this.users.map((innerItem) => {
                  item.assignto.map((assignto) => {
                    if (assignto == innerItem.id) {
                      if (!item.assigntoRole.includes(innerItem.role.slice(1))) {
                        item.assigntoRole += ` ${innerItem.role.charAt(0).toUpperCase() + innerItem.role.slice(1)
                          }`;
                      }
                    }
                  });
                });
              }
            });
          }
          if (this.title == "Plot") {
            this.rows.map((item) => {
              item.assigntoRole = "";
              if (item.project.assignto.length > 0) {
                this.users.map((innerItem) => {
                  item.project.assignto.map((assignto) => {
                    if (assignto == innerItem.id) {
                      if (!item.assigntoRole.includes(innerItem.role.slice(1))) {
                        item.assigntoRole += ` ${innerItem.role.charAt(0).toUpperCase() + innerItem.role.slice(1)
                          }`;
                      }
                    }
                  });
                });
              }
            });
          }
          this.columnsItem.map(async (item) => {
            if (item.type == "staticdropdown") {
              this.rows.map((data) => {
                this.users.map((data1) => {
                  if (data[item.field] == data1.id) {
                    data[item.field] = data1;
                  }
                });
              });
            }
            if (item.toggle == true) {
              this.rows.map((data) => {
                if (data.active == 0) {
                  data.active = "False";
                }
                if (data.active == 1) {
                  data.active = "True";
                }
              });
            }
            if (item.type == "dropdown") {
              if (item.url) {
                const accessToken = localStorage.getItem("accessToken");
                const baseApi = process.env.VUE_APP_APIENDPOINT;
                const requestOptionsUsers = {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                  },
                  url: `${baseApi}/${item.url}`,
                };

                await axios(requestOptionsUsers)
                  .then((response) => {
                    this.loadData = true;
                    this.loadData = false;
                    response.data.data.data
                      ? // ? (this.data[Array(item.url)] = response.data.data.data)
                      (this.data[item.url] = response.data.data.data)
                      : response.data.data
                        ? (this.data[item.url] = response.data.data)
                        : (this.data[item.url] = response.data);
                  })
                  .catch((error) => console.log(error, "error"));
                this.rows.map((data) => {
                  this.data[item.url] &&
                    this.data[item.url].map((data1, index) => {
                      if (data[item.field] == data1.id) {
                        if (data[item.field] == "srno") {
                          data[item.field] = index + 1;
                        }
                        data[item.field] = data1[item.responseValue];
                      }
                    });
                });
              }
            }
          });
          this.loadData = false;
          this.data["getUsers"] = this.users;
        })
        .catch((error) => {
          this.loadData = false;
        });
    },
    getAllDropdown() {
      // this.columnsDatatest.map(async (item) => {
      //   if (item.type == "staticdropdown") {
      //     this.rows.map((data) => {
      //       this.users.map((data1) => {
      //         if (data[item.field] == data1.id) {
      //           data[item.field] = data1.name;
      //         }
      //       });
      //     });
      //   }
      //   if (item.toggle == true) {
      //     this.rows.map((data) => {
      //       if (data.active == 0) {
      //         data.active = "False";
      //       }
      //       if (data.active == 1) {
      //         data.active = "True";
      //       }
      //     });
      //   }
      //   if (item.type == "dropdown") {
      //     const accessToken = localStorage.getItem("accessToken");
      //     const baseApi = process.env.VUE_APP_APIENDPOINT;
      //     const requestOptionsUsers = {
      //       method: "GET",
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: `Bearer ${accessToken}`,
      //       },
      //       url: `${baseApi}/${item.url}`,
      //     };
      //     await axios(requestOptionsUsers)
      //       .then((response) => {
      //         response.data.data.data
      //           ? // ? (this.data[Array(item.url)] = response.data.data.data)
      //           (this.data[item.url] = response.data.data.data)
      //           : response.data.data
      //             ? (this.data[item.url] = response.data.data)
      //             : (this.data[item.url] = response.data);
      //       })
      //       .catch((error) => console.log(error, "error"));
      //     this.rows.map((data) => {
      //       this.data[item.url] &&
      //         this.data[item.url].map((data1, index) => {
      //           if (data[item.field] == data1.id) {
      //             if (data[item.field] == "srno") {
      //               data[item.field] = index + 1;
      //             }
      //             data[item.field] = data1[item.responseValue];
      //           }
      //         });
      //     });
      //   }
      // });
    },
    async handelMultiple(id) {
      await axios({
        method: "GET",
        url: `${this.baseApi}/gettargetById/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          const data = json.data.data[0];
          this.$router.push(
            data.targetItem
              ? `/crm/editmultipletarget/${data.id}`
              : `/crm/edittarget/${data.id}`
          );
        })
        .catch((error) => console.log(error, "error"));
    },
    handleView(row) {
      this.viewPopUp = true;
      this.selectedID = row = row.row ? row.row.id : row.id;
    },
    handleEdit(row) {
      if (this.$route.query.trash) return
      row = row.row ? row.row : row;
      if (this.title == "Site Visit" && row.action == 0) {
        this.$swal({
          title: "Cancelled",
          text: "This Site Visit has been cancelled",
          icon: "error",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      if (this.popUpCompnent) {
        this.staticPopUp = true;
        this.getSelectedId(row);
        return;
      }

      if (JSON.stringify(this.formOptions.inputfiels) == "[]") {
        this.formOptions.inputFields = this.editData;
      } else {
        this.formOptions.inputFields = this.EditField.inputFields;
      }
      let countFields = 0;
      this.formOptions.inputFields.map((item) => {
        if (item.type !== "hidden") {
          countFields = countFields + 1;
        }
      });
      const newPath = this.routerpath.replace("add", "edit");
      if (this.multiplepath == "/crm/addmultipletarget") {
        this.handelMultiple(row.id);
        return;
      }
      if (this.newPage == true) {
        this.$router.push(`${newPath}/${row.id}`);
      }
      if (!this.newPage) {
        countFields < 10
          ? (this.handleOpenModel(), this.getSelectedId(row))
          : this.$router.push(`${newPath}/${row.id}`);
      }
      this.forceRerender();
      if (this.edit !== 1 && this.userRole !== "admin") {
        this.visible = false;
      }
    },
    handlePassword(row) {
      this.PassWordField.inputFields = [];
      this.editData = this.formOptions.inputFields;
      this.formOptions.inputFields.map((item) => {
        if (item.type == "password") {
          this.PassWordField.inputFields.push(item);
          item.class = "col-md-12";
        }
      });
      this.formOptions.inputFields = [];
      this.formOptions.inputFields = this.PassWordField.inputFields;
      const newPath = this.routerpath.replace("add", "edit");
      this.handleOpenModel(), this.getSelectedId(row);
      // : this.$router.push(`${newPath}?id=${row.id}`);
      this.forceRerender();
    },
    handleDelete(row) {
      this.formOptions.inputFields = this.EditField.inputFields;
      const ids = [];
      ids.push(row.id);

      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            let url = this.$route.query.trash ? this.moduleapi + '-permanent' : this.moduleapi;
            await axios({
              method: "delete",
              url: url,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              data: JSON.stringify({
                ids,
              }),
            }).then((json) => {
              // this.$router.push(this.options.submitRouterPath);
              this.getGridData();
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your data has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            }).catch((error) => {
              this.$swal({
                icon: "error",
                title: "Error!",
                text: error.response.data.message,
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
            })
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async restore(ids) {
      let url = this.moduleapi + '-restore';
      await axios({
        method: "post",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify({
          ids,
        }),
      }).then((json) => {
        this.getGridData();
        this.$swal({
          icon: "success",
          title: "Restored!",
          text: "Your data has been restored.",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      });
    },
    handleOpenModel() {
      this.visible = true;
    },
    // handleOpenPasswordModel() {
    //   this.passwordVisible = true;
    // },
    getSelectedId(row) {
      this.selectedID = row.id;
    },
    excelClick() {
      if (this.plot == true) {
        const fileURL = `${this.webEndpoint}/docs/plot.xls`;
        const fURL = document.createElement("a");

        fURL.href = fileURL;
        fURL.setAttribute("download", `Sample Plot.xls`);
        document.body.appendChild(fURL);

        fURL.click();
      } else if (this.lead == true) {
        const fileURL = `${this.webEndpoint}/docs/lead.xlsx`;
        const fURL = document.createElement("a");

        fURL.href = fileURL;
        fURL.setAttribute("download", `Sample lead.xlsx`);
        document.body.appendChild(fURL);

        fURL.click();
      }
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
  },
};
</script>
