const params = new URLSearchParams(window.location.search).has('id')
let userRole
if (localStorage.getItem('accessToken') !== null) {
  userRole = JSON.parse(localStorage.getItem('userData')).role
} else {
  userRole = false
}
export default [
  {
    path: '/reports/crm/leadreport',
    name: 'reports-crm-leadreport',
    component: () => import('@/views/reports/crm/LeadReport.vue'),
    meta: {
      pageTitle: 'Lead',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Lead',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/crm/channelpartner',
    name: 'reports-crm-channelpartner',
    component: () => import('@/views/reports/crm/ChannelPartnerReport.vue'),
    meta: {
      pageTitle: 'Channel Partners',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Channel Partners',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/crm/franchise',
    name: 'reports-crm-franchise',
    component: () => import('@/views/reports/crm/FranchiseReport.vue'),
    meta: {
      pageTitle: 'Franchise',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Franchise',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/crm/taskreport',
    name: 'reports-crm-taskreport',
    component: () => import('@/views/reports/crm/TaskReport.vue'),
    meta: {
      pageTitle: 'Task Report',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Task',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/hr/workingreport',
    name: 'reports-hr-workingreport',
    component: () => import('@/views/reports/hr/DailyworkingReport.vue'),
    meta: {
      pageTitle: 'Daily Working',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'HR',
          to: '/reports/hr'
        } : {
          text: 'HR',
          active: true
        },
        {
          text: 'Daily Working',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/hr/leavereport',
    name: 'reports-hr-leavereport',
    component: () => import('@/views/reports/hr/LeaveReport.vue'),
    meta: {
      pageTitle: 'Leave',
      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Leave',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/hr/loginreport',
    name: 'reports-hr-loginreport',
    component: () => import('@/views/reports/hr/LoginReport.vue'),
    meta: {
      pageTitle: 'Login',
      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Login',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/hr/employeeassetreport',
    name: 'reports-hr-employeeassetreport',
    component: () => import('@/views/reports/hr/EmployeeAssetReport.vue'),
    meta: {
      pageTitle: 'Employee Asset',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Employee Asset',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/hr/salaryreport',
    name: 'reports-hr-salaryreport',
    component: () => import('@/views/reports/hr/SalaryReport.vue'),
    meta: {
      pageTitle: 'Salary',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Salary',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/crm/complaintreport',
    name: 'reports-crm-complaintreport',
    component: () => import('@/views/reports/crm/ComplaintReport.vue'),
    meta: {
      pageTitle: 'Complaint',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Complaint ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/crm/customerdatabankreport',
    name: 'reports-crm-customerdatabankreport',
    component: () => import('@/views/reports/crm/CustomerDataBankReport.vue'),
    meta: {
      pageTitle: 'Customer Data Bank',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Customer Data Bank ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/crm/expensereport',
    name: 'reports-crm-expensereport',
    component: () => import('@/views/reports/crm/ExpenseReport.vue'),
    meta: {
      pageTitle: 'Expense',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Expense ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accountsummary/ledgerstatementreport',
    name: 'reports-accountsummary-ledgerstatementreport',
    component: () => import('@/views/reports/accountsummary/LedgerStatementReport.vue'),
    meta: {
      pageTitle: 'Ledger Statement',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Account Summary',
          to: '/reports/accountsummary'
        } : {
          text: 'Account Summary',
          active: true
        },
        {
          text: 'Ledger Statement',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accountsummary/bankreconciliationreport',
    name: 'reports-accountsummary-bankreconciliationreport',
    component: () => import('@/views/reports/accountsummary/BankReconciliationReport.vue'),
    meta: {
      pageTitle: 'Bank Reconcilition',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Account Summary',
          to: '/reports/accountsummary'
        } : {
          text: 'Account Summary',
          active: true
        },
        {
          text: 'Bank Reconciliation',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accountsummary/outstandingreport',
    name: 'reports-accountsummary-outstandingreport',
    component: () => import('@/views/reports/accountsummary/OutstandingReport.vue'),
    meta: {
      pageTitle: 'Out Standing',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Account Summary',
          to: '/reports/accountsummary'
        } : {
          text: 'Account Summary',
          active: true
        },

        {
          text: 'OutStanding',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accountsummary/daybookreport',
    name: 'reports-accountsummary-daybookreport',
    component: () => import('@/views/reports/accountsummary/DaybookReport.vue'),
    meta: {
      pageTitle: 'Day Book',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Account Summary',
          to: '/reports/accountsummary'
        } : {
          text: 'Account Summary',
          active: true
        },
        {
          text: 'Day Book',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accountsummary/tdsreport',
    name: 'reports-accountsummary-tdsreport',
    component: () => import('@/views/reports/accountsummary/TdsReport.vue'),
    meta: {
      pageTitle: 'TDS',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Account Summary',
          to: '/reports/accountsummary'
        } : {
          text: 'Account Summary',
          active: true
        },


        {
          text: 'TDS',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accountsummary/stockreport',
    name: 'reports-accountsummary-stockreport',
    component: () => import('@/views/reports/accountsummary/StockReport.vue'),
    meta: {
      pageTitle: 'Stock',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'Accountsummary',
          active: true
        },
        {
          text: 'Stock',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/crm/followupreport',
    name: 'reports-crm-followupreport',
    component: () => import('@/views/reports/crm/FollowupReports.vue'),
    meta: {
      pageTitle: 'Follow Up',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Followup',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/crm/saledeedreport',
    name: 'reports-crm-saledeedreport',
    component: () => import('@/views/reports/crm/SaleDeedReport.vue'),
    meta: {
      pageTitle: 'Sale Deed',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Sale Deed',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accounts/landpurchasereport',
    name: 'reports-accounts-landpurchasereport',
    component: () => import('@/views/reports/accounts/LandPurchaseReport.vue'),
    meta: {
      pageTitle: 'Land Purchase',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Accounts',
          to: '/reports/accounts'
        } : {
          text: 'Accounts',
          active: true
        },
        {
          text: 'Land Purchase ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accounts/receiptreport',
    name: 'reports-accounts-receiptreport',
    component: () => import('@/views/reports/accounts/ReceiptReports.vue'),
    meta: {
      pageTitle: 'Receipt',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Accounts',
          to: '/reports/accounts'
        } : {
          text: 'Accounts',
          active: true
        },
        {
          text: 'Receipt ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accounts/paymentreport',
    name: 'reports-accounts-paymentreport',
    component: () => import('@/views/reports/accounts/PaymentReport.vue'),
    meta: {
      pageTitle: 'Payment',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Accounts',
          to: '/reports/accounts'
        } : {
          text: 'Accounts',
          active: true
        },
        {
          text: 'Payment ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accounts/voucherreport',
    name: 'reports-accounts-voucherreport',
    component: () => import('@/views/reports/accounts/VoucherReport.vue'),
    meta: {
      pageTitle: 'Voucher',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Accounts',
          to: '/reports/accounts'
        } : {
          text: 'Accounts',
          active: true
        },
        {
          text: 'Voucher ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accounts/commissionreport',
    name: 'reports-accounts-commissionreport',
    component: () => import('@/views/reports/accounts/CommissionReport.vue'),
    meta: {
      pageTitle: 'Commission',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Accounts',
          to: '/reports/accounts'
        } : {
          text: 'Accounts',
          active: true
        },
        {
          text: 'Commission ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accounts/chequereturnreport',
    name: 'reports-accounts-chequereturnreport',
    component: () => import('@/views/reports/accounts/ChequereturnReport.vue'),
    meta: {
      pageTitle: 'Cheque Return',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Accounts',
          to: '/reports/accounts'
        } : {
          text: 'Accounts',
          active: true
        },
        {
          text: 'Cheque Return ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accounts/profitlossreport',
    name: 'reports-accounts-profitlossreport',
    component: () => import('@/views/reports/accounts/ProfitlossReport.vue'),
    meta: {
      pageTitle: 'Profit Loss',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Accounts',
          to: '/reports/accounts'
        } : {
          text: 'Accounts',
          active: true
        },
        {
          text: 'Profit Loss ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accounts/balancesheetreport',
    name: 'reports-accounts-balancesheetreport',
    component: () => import('@/views/reports/accounts/BalancesheetReport.vue'),
    meta: {
      pageTitle: 'Balance Sheet',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Accounts',
          to: '/reports/accounts'
        } : {
          text: 'Accounts',
          active: true
        },
        {
          text: 'Balance Sheet ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/accounts/trialbalancereport',
    name: 'reports-accounts-trialbalancereport',
    component: () => import('@/views/reports/accounts/TrialbalanceReport.vue'),
    meta: {
      pageTitle: 'Trial Balance',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Accounts',
          to: '/reports/accounts'
        } : {
          text: 'Accounts',
          active: true
        },
        {
          text: 'Trial Balance ',
          active: true
        }
      ]
    }
  },

  {
    path: '/reports/accounts/otherpurchasereport',
    name: 'reports-accounts-otherpurchasereport',
    component: () => import('@/views/reports/accounts/OtherpurchaseReport.vue'),
    meta: {
      pageTitle: 'Other Purchase',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'Accounts',
          to: '/reports/accounts'
        } : {
          text: 'Accounts',
          active: true
        },
        {
          text: 'Other Purchase ',
          active: true
        }
      ]
    }
  },

  {
    path: '/reports/commission/commissionreport',
    name: 'reports-commission-commissionreport',
    component: () => import('@/views/reports/commission/CommissionReport.vue'),
    meta: {
      pageTitle: 'Commission',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'commission',
          active: true
        },
        {
          text: 'Commission ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/master/ledgerreport',
    name: 'reports-master-ledgerreport',
    component: () => import('@/views/reports/master/LedgerReport.vue'),
    meta: {
      pageTitle: 'Ledger',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'Ledger',
          active: true
        },
        {
          text: 'Ledger ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/master/customerreport',
    name: 'reports-master-customerreport',
    component: () => import('@/views/reports/master/CustomerReport.vue'),
    meta: {
      pageTitle: 'Customer',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'customer',
          active: true
        },
        {
          text: 'Customer ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/crm/salesreport',
    name: 'reports-crm-salesreport',
    component: () => import('@/views/reports/crm/SalesReport.vue'),
    meta: {
      pageTitle: 'Sales',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Sales ',
          active: true
        }
      ]
    }
  },


  {
    path: '/reports/crm/landsalesreport',
    name: 'reports-crm-landsalesreport',
    component: () => import('@/views/reports/crm/LandSalesReport.vue'),
    meta: {
      pageTitle: 'Land Sales',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Land Sales',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/crm/sitevisitreport',
    name: 'reports-crm-sitevisitreport',
    component: () => import('@/views/reports/crm/SiteVisitReport.vue'),
    meta: {
      pageTitle: 'Site Visit',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Site Visit',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/crm/plotreport',
    name: 'reports-crm-plotreport',
    component: () => import('@/views/reports/crm/PlotReport.vue'),
    meta: {
      pageTitle: 'Plot',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Plot ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/account/plotstatement',
    name: 'reports-account-plotstatement',
    component: () => import('@/views/reports/accounts/PlotStatement.vue'),
    meta: {
      pageTitle: 'Plot',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'Accounts',
          active: true
        },
        {
          text: 'Plot Statement',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/account/bulklandstatement',
    name: 'reports-account-bulklandstatement',
    component: () => import('@/views/reports/accounts/BulkLandStatement.vue'),
    meta: {
      pageTitle: 'Bulk Land',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'Accounts',
          active: true
        },
        {
          text: 'Bulk Land',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/crm/projectreport',
    name: 'reports-crm-projectreport',
    component: () => import('@/views/reports/crm/ProjectReport.vue'),
    meta: {
      pageTitle: 'Project',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'REPORTS',
          to: '/reports'
        } : {
          text: 'REPORTS',
          active: true
        },

        userRole != 'admin' ? {
          text: 'CRM',
          to: '/reports/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Project ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/hr/registerreport',
    name: 'reports-hr-registerreport',
    component: () => import('@/views/reports/hr/RegisterReport.vue'),
    meta: {
      pageTitle: 'Register',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Register',
          active: true
        }
      ]
    }
  },

  {
    path: '/reports/hr/courierreport',
    name: 'reports-hr-courierreport',
    component: () => import('@/views/reports/hr/CourierReport.vue'),
    meta: {
      pageTitle: 'Courier',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Courier',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/hr/attendancereport',
    name: 'reports-hr-attendancereport',
    component: () => import('@/views/reports/hr/AttendanceReport.vue'),
    meta: {
      pageTitle: 'Attandance',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Attandance ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/hr/employeereport',
    name: 'reports-hr-employeereport',
    component: () => import('@/views/reports/hr/EmployeeReport.vue'),
    meta: {
      pageTitle: 'Employee',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Employee ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/hr/employeeperformancereport',
    name: 'reports-hr-employeeperformancereport',
    component: () => import('@/views/reports/hr/EmployeeperformanceReport.vue'),
    meta: {
      pageTitle: 'Employee Performance',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Employee Perfomance ',
          active: true
        }
      ]
    }
  },
  {
    path: '/reports/legal/sales-legal',
    name: 'reports-sales-legal',
    component: () => import('@/views/reports/legal/SalesLegalReport.vue'),
    meta: {
      pageTitle: 'Sales Legal',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'Legal',
          active: true
        },
        {
          text: 'Sales Legal',
          active: true
        }
      ]
    }
  },

  {
    path: '/reports/legal/purchase-legal',
    name: 'reports-purchase-legal',
    component: () => import('@/views/reports/legal/PurchaseLegalReport.vue'),
    meta: {
      pageTitle: 'Purchase Legal',

      breadcrumb: [
        {
          text: 'REPORTS',
          active: true
        },
        {
          text: 'Legal',
          active: true
        },
        {
          text: 'Purchase Legal',
          active: true
        }
      ]
    }
  }
]
