var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-between"},_vm._l((_vm.viewFields),function(item,index){return _c('div',{key:index,staticClass:"mt-2",class:item.class},[(item.type == 'image')?_c('div',[_c('label',{staticClass:"viewLabel"},[_vm._v(_vm._s(item.label))]),_c('b-media-aside',[_c('b-link',[_c('b-img',{ref:"previewEl",refInFor:true,attrs:{"rounded":"","src":_vm.data[item.field] && _vm.data[item.field] !== null
                ? _vm.data[item.field].includes(_vm.imagePath)
                  ? _vm.data[item.field]
                  : _vm.imagePath + _vm.data[item.field]
                : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg',"height":"80"}})],1)],1)],1):(item.type == 'text')?_c('div',[_c('label',{staticClass:"viewLabel"},[_vm._v(_vm._s(item.label)+" :")]),_c('p',{staticClass:"viewValue"},[_vm._v(_vm._s(_vm.data[item.field]))])]):(item.type == 'date')?_c('div',[_c('label',{staticClass:"viewLabel"},[_vm._v(_vm._s(item.label)+" :")]),_c('p',{staticClass:"viewValue"},[_vm._v(" "+_vm._s(_vm.data[item.field] ? _vm.moment(_vm.data[item.field]).format("DD/MM/YYYY") : "-")+" ")])]):(item.type == 'datetime')?_c('div',[_c('label',{staticClass:"viewLabel"},[_vm._v(_vm._s(item.label)+" :")]),_c('p',{staticClass:"viewValue"},[_vm._v(" "+_vm._s(_vm.data[item.field] ? _vm.moment(_vm.data[item.field]).format("DD/MM/YYYY h:m A") : "-")+" ")])]):(item.type == 'fieldInObj')?_c('div',[_c('label',{staticClass:"viewLabel"},[_vm._v(_vm._s(item.label)+" :")]),(_vm.data[item.field] && item.childFieldType == 'text')?_c('p',{staticClass:"viewValue"},[_vm._v(" "+_vm._s(_vm.data[item.field][item.childField])+" ")]):(item.childFieldType == 'attachment' && _vm.data[item.field])?_c('attachment',{attrs:{"data":_vm.data[item.field][item.childField]}}):_vm._e()],1):(item.type == 'email')?_c('div',[_c('label',{staticClass:"viewLabel"},[_vm._v(_vm._s(item.label)+" :")]),_c('p',{staticClass:"viewValue"},[_vm._v(_vm._s(_vm.data[item.field]))])]):(item.field == 'anniversarydate' && _vm.data.maritalstatus == 'Married')?_c('div',[_c('label',{staticClass:"viewLabel"},[_vm._v(_vm._s(item.label)+" :")]),_c('p',{staticClass:"viewValue"},[_vm._v(_vm._s(_vm.data[item.field]))])]):(item.type == 'heading')?_c('div',{staticClass:"mb-1 mt-3"},[_c('span',{staticStyle:{"font-weight":"500","font-size":"1.285rem"}},[_vm._v(_vm._s(item.label))])]):(
        item.type == 'dropdown' &&
        (_vm.loginData.role == 'admin' || _vm.data.id != _vm.loginData.id)
      )?_c('div',[_c('label',{staticClass:"viewLabel"},[_vm._v(_vm._s(item.label)+" :")]),(_vm.data[item.field] && Array.isArray(_vm.data[item.field]))?_c('p',{staticClass:"viewValue"},[_vm._v(" "+_vm._s(_vm.data[item.field] ? _vm.data[item.field][0][item.responseValue] : "")+" ")]):(_vm.data[item.field])?_c('p',{staticClass:"viewValue"},[_vm._v(" "+_vm._s(_vm.data[item.field] ? _vm.data[item.field][item.responseValue] : "")+" ")]):_vm._e()]):(item.type == 'attachment')?_c('div',[_c('label',{staticClass:"viewLabel"},[_vm._v(_vm._s(item.label)+" :")]),_c('p',[_c('attachment',{attrs:{"data":_vm.data[item.field]}})],1)]):(
        item.type == 'leadaccess' && _vm.data.position && _vm.data.position.name == 'leadmanage'
      )?_c('div',[_c('label',{staticClass:"viewLabel"},[_vm._v(_vm._s(item.label)+" :")]),_c('p',{staticClass:"viewValue"},[_vm._v(_vm._s(_vm.data[item.field]))])]):(item.type == 'multiple')?_c('div',[_c('div',{staticStyle:{"width":"100%","max-height":"500px","overflow":"auto"}},[_c('table',{staticClass:"table table-bordered",staticStyle:{"min-width":"15cm"}},[_c('thead',{staticClass:"text"},[_c('tr',[_c('th',[_vm._v("Sr. No")]),_vm._l((item.tableHead),function(th,thIndex){return _c('th',{key:thIndex},[_vm._v(_vm._s(th))])})],2)]),_c('tbody',_vm._l((typeof _vm.data[item.field] == 'string'
                ? JSON.parse(_vm.data[item.field])
                : _vm.data[item.field]),function(td,id){return _c('tr',{key:id},[_c('td',[_vm._v(_vm._s(id + 1))]),_vm._l((item.tableField),function(multi,multiIn){return _c('td',{key:multiIn},[(multi.type == 'text')?_c('div',[_vm._v(" "+_vm._s(td[multi.field])+" ")]):_vm._e(),(multi.type == 'attachment')?_c('div',[_c('attachment',{attrs:{"data":td[multi.field]}})],1):_vm._e()])})],2)}),0)])])]):_vm._e(),_vm._t("extraView",null,{"data":_vm.data,"item":item})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }