<template>
  <div>
    <b-row md="12" class="d-flex justify-content-end pr-5">
      <b-button variant="success" size="md" class="ml-2 btn-icon" v-if="data.approved1 == 'Approved'"
        @click='handelRecieptPDF(data.id)'>
        <feather-icon icon="DownloadIcon" />
      </b-button>
      <vue-html2pdf v-if="receiptData" :show-layout="false" :float-layout="true" :enable-download="true"
        :preview-modal="false" :paginate-elements-by-height="1400" filename="Receipt" :html2canvas="{}" :jsPDF="{
          format: 'a5',
        }" :pdf-quality="3" :manual-pagination="false" pdf-format="a5" :pdf-margin="0" pdf-orientation="landscape"
        pdf-content-width="1800px" @progress="onProgress($event)" ref="html2Pdf">
        <section slot="pdf-content">
          <!-- Include your common header component with the pageNumber prop -->
          <print-comp :receiptData="receiptData" />
        </section>
      </vue-html2pdf>
    </b-row>
    <b-row class="row justify-content-between">
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Receipt Type :</label>
        <p class="viewValue">{{ data.receipt_type }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Receipt Date :</label>
        <p class="viewValue">{{ data.receiptdate && data.receiptdate !== '0000-00-00'
          ? moment(data.receiptdate).format('DD/MM/YYYY') : "-" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Transaction Type :</label>
        <p class="viewValue">{{ data.transactiontype }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Company Name :</label>
        <p class="viewValue">
          {{ data.companyname ? data.companyname.name : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Client Name :</label>
        <p class="viewValue">
          {{ data.clientname ? data.clientname.name : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="data.receipt_type == 'Bulk Land'">
        <label class="viewLabel"> Village :</label>
        <p class="viewValue">
          {{ data.land ? (data.land.villagename + `(${data.land.surveynonew})`) : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2" v-else>
        <label class="viewLabel"> Project :</label>
        <p class="viewValue">
          {{ data.project ? data.project.projectname : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Ledger Name :</label>
        <p class="viewValue">
          {{ data.ledgername ? data.ledgername.name : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Transaction Date :</label>
        <p class="viewValue">{{ data.transaction_date && data.transaction_date !== '0000-00-00'
          ? moment(data.transaction_date).format('DD/MM/YYYY') : "-" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Remarks :</label>
        <p class="viewValue">{{ data.remarks }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Attachment1 :</label>
        <attachment :data="data.attachment1" />
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Attachment2 :</label>
        <attachment :data="data.attachment2" />
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Attachment3 :</label>
        <attachment :data="data.attachment3" />
      </b-col>
      <b-col md="12" class="mt-2" v-if="receipt_type == 'Bulk Land'">
        <div style="width: 100%; overflow: auto">
          <table class="table table-bordered" style="min-width: 25cm">
            <thead>
              <tr>
                <th style="font-size: 11px !important">Sr. No.</th>
                <th style="font-size: 11px !important">Sales Date</th>
                <th style="font-size: 11px !important">Plot No</th>
                <th style="font-size: 11px !important">Total Plot Value</th>
                <th style="font-size: 11px !important">Remaining Amount</th>
                <th style="font-size: 11px !important">Amount</th>
                <th style="font-size: 11px !important">Kasar</th>
                <th style="font-size: 11px !important">Remaining</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data.receipt_items" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ item.sales_date.split("-").reverse().join("/") }}
                </td>
                <td>
                  {{ item.plot_data.plotno }}
                </td>
                <td>
                  {{ item.total_plot_value }}
                </td>
                <td>
                  {{ item.remaining_amount }}
                </td>
                <td>
                  {{ item.amount }}
                </td>
                <td>
                  {{ item.kasar }}
                </td>
                <td>
                  {{ item.remaining }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-end">
      <b-col md="4" class="mt-2" v-if="data.receipt_type == 'Bulk Land'">
        <label class="viewLabel"> Total Payment:</label>
        <p class="viewValue">{{ data.land_total_payment ? data.land_total_payment : 0 }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="data.receipt_type == 'Bulk Land'">
        <label class="viewLabel"> Remaining Amount:</label>
        <p class="viewValue">{{ data.land_remaining_amount ? data.land_remaining_amount : 0 }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="data.receipt_type == 'Bulk Land'">
        <label class="viewLabel"> Kasar:</label>
        <p class="viewValue">{{ data.kasar ? data.kasar : 0 }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Total Amount:</label>
        <p class="viewValue">{{ data.totalamount ? data.totalamount : 0 }}</p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import axios from "@/components/axios";
import Attachment from "@/components/Attechment.vue";
import moment from 'moment';
import html2pdf from "html2pdf.js";
import VueHtml2pdf from "vue-html2pdf";
import printComp from "../../../views/print/printReceipt.vue";
// import printComp from "../views/print/printReceipt.vue";

export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,
    Attachment,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    printComp,
    html2pdf,
    VueHtml2pdf
  },
  props: {
    closepopup: {
      default: () => { },
    },
    selectedID: {
      default: 0,
    },
  },
  data() {
    return {
      moment,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      webEndpoint: process.env.VUE_APP_WEBENDPOINT,
      data: [],
      name: "",
      receiptData: "",
      loginData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    onProgress(event) {
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    async handelRecieptPDF(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getreceiptById/${id}`,
      };

      await axios(requestOptions)
        .then((json) => {
          this.receiptData = json.data.data;
          let year = moment(this.receiptData.enterdatetime).year()
          let id = this.receiptData.id.toString().padStart(5, '0')
          this.receiptData.receiptNo = 'NGR-' + year + '-' + id

          if (this.receiptData && this.receiptData.receipt_items.length > 30) {
            this.$swal({
              title: "Denied",
              text: "Cannot generate receipt which contains more than 30 plot payments",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.receiptData.paymentDetails = ''
            if ((parseFloat(this.receiptData?.receipt_items[0]?.remaining) + parseFloat(this.receiptData?.receipt_items[0]?.amount)) == parseFloat(this.receiptData.receipt_items[0].sales_data.totalpayment)) {
              this.receiptData.paymenttype = this.receiptData?.receipt_items[0].sales_data.paymenttype
            }
            else if (this.receiptData?.receipt_items[0].sales_data.bookingtype == 'EMI') {
              this.receiptData.paymenttype = 'EMI'
            }
            else {
              this.receiptData.paymenttype = 'Part Payment'
            }
            this.$refs.html2Pdf.generatePdf();
          }
          // this.generatePDF();
          //   var doc = new jsPDF('p', 'pt', 'A4');
          //  margins = {
          //     top: 80,
          //     bottom: 60,
          //     left: 40,
          //     width: 522
          // };

          // doc.fromHTML(this.$refs.testHtml, margins.left, margins.top,{
          //   'width' : margins.width
          // });

          // doc.save('test.pdf');

          // let url = process.env.VUE_APP_IMAGE_PATH + json.data.data.path
          // const ex = "pdf";

          // axios({
          //   url,
          //   method: "GET",
          //   responseType: "blob",
          // }).then((response) => {
          //   const fileURL = window.URL.createObjectURL(
          //     new Blob([response.data])
          //   );
          //   const fURL = document.createElement("a");

          //   fURL.href = fileURL;
          //   fURL.setAttribute("download", `Receipt.${ex}`);
          //   document.body.appendChild(fURL);

          //   fURL.click();
          // });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getreceiptById/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.data = Array.isArray(json.data.data)
            ? json.data.data[0]
            : json.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>
<style>
.viewLabel {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.viewValue {
  font-size: 15px !important;
  color: black;
}
</style>
