<template>
  <div>
    <b-row class="row justify-content-between">
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Lead Source:</label>
        <p class="viewValue">
            <p class="viewValue">{{data.leadsource && data.leadsource.length>0 ? data.leadsource[0].name:''}}</p>

        </p>
      </b-col>
      <b-col
        md="4"
        class="mt-2"
        v-if="data.leadsource && data.leadsource.length>0 && data.leadsource[0].name == 'Campaign'"
      >
        <label class="viewLabel">Campaign</label>
        <p class="viewValue">
          {{ data.campaign ? data.campaign.name : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel">Sub Source:</label>
        <p class="viewValue">
          {{ data.subsource && data.subsource.length > 0 ? data.subsource[0].name : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Client Name :</label>
        <p class="viewValue">{{ data.name }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel">Lead Date :</label>
        <p class="viewValue">{{ data.leaddate }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Mobile :</label>
        <p class="viewValue">{{ data.mobile }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Email:</label>
        <p class="viewValue">{{ data.email }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Lead Category:</label>
        <p class="viewValue">
          {{
            data.leadcategory && data.leadcategory.length > 0
              ? data.leadcategory[0].name
              : ""
          }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Occupation :</label>
        <p class="viewValue">{{ data.occupation ? data.occupation : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Comapany Name :</label>
        <p class="viewValue">{{ data.previous_company ? data.previous_company : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Job Title :</label>
        <p class="viewValue">{{ data.lead_jobtitle }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Address :</label>
        <p class="viewValue">{{ data.address }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Pin Code :</label>
        <p class="viewValue">{{ data.pincode }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> City:</label>
        <p class="viewValue">{{ data.city }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Area :</label>
        <p class="viewValue">{{ data.area }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> State :</label>
        <p class="viewValue">{{ data.state }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Remark :</label>
        <p class="viewValue">{{ data.remarks }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Type:</label>
        <p class="viewValue">
          {{ data.assignto && data.assignto.length > 0 ? data.assignto[0].type : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Assign to:</label>
        <p class="viewValue">
          {{ data.assignto && data.assignto.length > 0 ? data.assignto[0].name : "" }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import axios from "@/components/axios";
import Attachment from "@/components/Attechment.vue";
export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,
    Attachment,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  props: {
    closepopup: {
      default: () => {},
    },
    selectedID: {
      default: 0,
    },
  },
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      data: {},
      name: "",
      loginData: JSON.parse(localStorage.getItem("userData")),
      invoiceno: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getleadById/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.data = Array.isArray(json.data.data) ? json.data.data[0] : json.data.data;
          // this.invoiceno = this.data[0] && this.data.length>0 ? this.data[0].invoiceno :'';
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>
<style>
.viewLabel {
  font-size: 15px !important;
  font-weight: 500 !important;
}
.viewValue {
  font-size: 15px !important;
  color: black;
}
</style>
