<style>
.timeline-item {
  border-left: 1px solid #cccbce !important;
}
</style>
<template>
  <section id="dashboard-analytics">
    <div class="h-full d-flex flex-column" v-if="IsKyc">
      <b-row class="match-height">
        <b-col lg="12">
          <b-row class="match-height d-flex justify-content-center">
            <b-col
              cols="6"
              md="3"
              lg="2"
              v-if="$store.getters['app/widgetAccess'](data.title)"
              v-for="data in sidebarDataCp"
              :key="data.title"
            >
              <statistic-card-vertical
                :icon="data.icon"
                :statistic="data.title"
                :color="data.color"
                :route="data.route"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          lg="12"
          v-if="
            $store.getters['app/widgetAccess']('Followup') || userData.type == 'admin'
          "
        >
          <analytics-support-tracker :data="data.supportTracker" />
        </b-col>
        <b-col
          lg="6"
          v-if="
            $store.getters['app/widgetAccess']('Site Visit') || userData.type == 'admin'
          "
        >
          <site-visit-dashboard
            v-if="siteVisitAccess && visitFlag"
            @visitDataItem="getVisitDataItem"
            :visitData="visitData"
          />
        </b-col>

        <b-col
          lg="6"
          v-if="$store.getters['app/widgetAccess']('Task') || userData.type == 'admin'"
        >
          <b-card class="h-100">
            <div class="h-100">
              <b-button
                block
                ref="toggle"
                v-b-toggle.accordion-3
                variant="outline-primary"
                >Task Details</b-button
              >
              <b-collapse
                id="accordion-3"
                style="z-index: 31"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <task-widget />
                </b-card-body>
              </b-collapse>
            </div>
          </b-card>
        </b-col>
        <b-col lg="12">
          <admin-dashboard :data="statisticsItemsAdmin" />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col lg="3" v-if="$store.getters['app/widgetAccess']('View Sales')">
          <ecommerce-medal :data="congratulations" />
        </b-col>
        <!-- <b-col md="4">
          <ecommerce-earnings-chart :data="earningsChart" />
        </b-col> -->
        <b-col
          :lg="5"
          v-if="$store.getters['app/widgetAccess']('Project-Land-Customer details')"
        >
          <ecommerce-statistics :data="statisticsItems" :cp="true" />
        </b-col>

        <b-col lg="4">
          <lead-piechart
            v-if="$store.getters['app/widgetAccess']('Lead categories') && LeadPie"
            :data="earningsChart"
            :LeadData="LeadData"
          />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-center">
          <leaddetails />
        </b-col>

        <b-col lg="4" md="6"
          ><b-tabs
            horizontal
            content-class=" mt-1 mt-md-0"
            pills
            nav-wrapper-class="div"
            nav-class="nav-left"
          >
            <b-tab
              @click="changeTab('a')"
              v-if="$store.getters['app/widgetAccess']('Lead List')"
              :active="Tabvalue == 'a' && leadAccess ? true : false"
            >
              <template #title>
                <feather-icon icon="LifeBuoyIcon" size="18" />
                <span class="font-weight-bold">Lead</span>
              </template>
              <show-lead-view
                class="example"
                style="overflow-y: scroll; height: 17cm"
                :LeadData="LeadData"
                @LeadDataItem="getLeadDataItem"
                :paginationTotalProp="paginationTotal"
                :paginationStartProp="paginationStart"
                :paginationEndProp="paginationStart"
              />
            </b-tab>

            <b-tab
              @click="changeTab('b')"
              :active="Tabvalue == 'b' ? true : false"
              v-if="$store.getters['app/widgetAccess']('Site Visit List')"
            >
              <template #title>
                <feather-icon icon="MapIcon" size="18" />
                <span class="font-weight-bold">Site Visit</span>
              </template>
              <show-visit-view
                class="example"
                style="overflow-y: scroll; height: 15cm"
                :visitData="visitData"
                @visitDataItem="getVisitDataItem"
              />
            </b-tab>
          </b-tabs>
        </b-col>
        <b-col id="Customeview" lg="8">
          <show-lead-details
            v-if="Tabvalue == 'a' && $store.getters['app/widgetAccess']('Lead List')"
            style="overflow-y: scroll; height: 12cm"
            class="example"
            :LeadDataItem="LeadDataItem"
          />
          <show-visit-details
            v-if="
              Tabvalue == 'b' && $store.getters['app/widgetAccess']('Site Visit List')
            "
            @UpdateData="UpdateData"
            :visitDataItem="visitDataItem"
          />
        </b-col>
      </b-row>

      <lead-manager-card />

      <!-- <b-col md="3" /> -->
      <div v-if="userRole !== 'admin'">
        <b-row class="match-height d-flex">
          <b-col md="2" v-for="data in sidebarData" :key="data.title">
            <statistic-card-vertical
              :icon="data.icon"
              :statistic="data.title"
              :color="data.color"
              :route="data.route"
            />
          </b-col>
        </b-row>
      </div>

      <!-- <b-row class="match-height" v-if="userRole == 'channelpartner'">
        <b-col md="4">
          <div @click="OfferClick">
            <analytics-congratulation :data="congratulations" />
          </div>
          <ecommerce-earnings-chart :data="earningsChart" />
        </b-col>
        <b-col md="8">
          <chartjs-bar-chart />
        </b-col>
      </b-row> -->
    </div>
    <div v-if="agreementShow && userData.role == 'channelpartner'">
      <b-modal
        v-model="agreementShow"
        size="lg"
        :hide-header-close="true"
        cancel-variant="outline-secondary"
        ok-title="Submit"
        no-close-on-backdrop
        hide-footer
        title="Agreement"
      >
        I hereby declare that all the information provided by me above is true to the best
        of my knowledge and belief and I am submitting this registration form to register
        with Project of Nestoria Group as a Channel Partner and I agree and shall abide by
        the terms and conditions specified on the reverse of the page.
        <div class="d-flex mt-2 justify-content-end">
          <b-button
            variant="success"
            size="md"
            class="ml-2"
            @click="handelClickAgreement('accept')"
          >
            Accept
          </b-button>
        </div>
      </b-modal>
    </div>
    <div v-if="!IsKyc">
      <b-modal
        ref="mymodal"
        size="lg"
        :hide-header-close="true"
        cancel-variant="outline-secondary"
        ok-title="Submit"
        no-close-on-backdrop
        hide-footer
        :title="
          pending
            ? 'Your KYC is Pending'
            : rejected
            ? 'Your KYC has been Rejected'
            : 'Please Update Your KYC'
        "
      >
        <kyc :back="true" :pending="pending" :rejected="rejected" />
      </b-modal>
    </div>
  </section>
</template>

<script>
import ChartjsBarChart from "../../charts-and-maps/charts/chartjs/ChartjsBarChart.vue";
import {
  BRow,
  BCol,
  BModal,
  BTabs,
  BTab,
  BButton,
  BCardHeader,
  BCollapse,
  VBToggle,
  BCardBody,
  BCard,
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import AnalyticsCongratulation from "./AnalyticsCongratulation.vue";
import axios from "@/components/axios";
import kyc from "../../pages/profile/ProfileKyc.vue";

import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
// import InvoiceList from "@/views/apps/invoice/invoice-list/InvoiceList.vue";
import CustomerDashboard from "./CustomerDashboard.vue";
import AdminDashboard from "./AdminDashboard.vue";
import LeadPiechart from "./leadpiechart.vue";
import EcommerceEarningsChart from "../ecommerce/EcommerceEarningsChart.vue";
import EcommerceMedal from "../ecommerce/EcommerceMedal.vue";
import AnalyticsAvgSessions from "./AnalyticsAvgSessions.vue";
import AnalyticsSupportTracker from "./AnalyticsSupportTracker.vue";
import AnalyticsTimeline from "./AnalyticsTimeline.vue";
import AnalyticsSalesRadarChart from "./AnalyticsSalesRadarChart.vue";
import SiteVisitDashboard from "./SiteVisitDashboardForEmployees.vue";
import AnalyticsAppDesign from "./AnalyticsAppDesign.vue";
import showLeadView from "../../card/card-advance/showLeadView.vue";
import showVisitView from "../../card/card-advance/showVisitView.vue";

import showLeadDetails from "../../card/card-advance/showLeadDetails.vue";
import showVisitDetails from "../../card/card-advance/showVisitDetails.vue";
import EcommerceStatistics from "../ecommerce/EcommerceStatistics.vue";
import LeadManagerCard from "../../card/card-advance/leadManagerCard.vue";
import taskWidget from "./taskWidget.vue";
import leaddetails from "./leaddetails.vue";
import BCardCode from "@core/components/b-card-code";

export default {
  components: {
    BCard,
    BCardCode,
    taskWidget,
    leaddetails,
    AdminDashboard,
    SiteVisitDashboard,
    BModal,
    ChartjsBarChart,
    kyc,
    EcommerceEarningsChart,
    StatisticCardVertical,
    EcommerceMedal,
    AnalyticsCongratulation,
    BRow,
    BCol,
    showLeadView,
    BTabs,
    BTab,
    BButton,
    showLeadDetails,
    EcommerceStatistics,
    CustomerDashboard,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    showVisitDetails,
    showVisitView,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
    LeadPiechart,
    LeadManagerCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    VBToggle,
    // InvoiceList,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      visitFlag: false,
      agreementShow: false,
      earningsChart: {
        series: [53, 16, 31],
      },
      congratulations: {
        name: "",
        saleToday: "0",
      },
      statisticsItems: [
        // {
        //   icon: "TrendingUpIcon",
        //   color: "light-primary",
        //   title: "0",
        //   subtitle: "Sales",
        //   customClass: "mb-2 mb-xl-0",
        // },
        {
          icon: "UserIcon",
          color: "light-info",
          title: "0",
          subtitle: "Customers",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "BoxIcon",
          color: "light-danger",
          title: "0",
          subtitle: "Projects",
          customClass: "mb-2 mb-sm-0",
        },
        // {
        //   icon: "rupee",
        //   color: "light-success",
        //   title: "0",
        //   subtitle: "Commission",
        //   customClass: "",
        // },
        {
          icon: "MapIcon",
          color: "light-warning",
          title: "0",
          subtitle: "Bulk Lands",
          customClass: "mb-2 mb-sm-0",
        },
      ],
      sidebarDataCp: [
        {
          icon: "MapIcon",
          color: "danger",
          title: "Show Site Visit",
          route: "/crm/sitevisit",
        },
        {
          icon: "MapIcon",
          color: "danger",
          title: "Book Site Visit",
          route: "/crm/addsitevisit",
        },
        {
          icon: "UsersIcon",
          color: "success",
          title: "Add New Customer",
          route: "/crm/customer/addcustomer",
        },
        {
          icon: "EditIcon",
          color: "primary",
          title: "Book Plot",
          route: "/project",
        },
        {
          icon: "GridIcon",
          color: "danger",
          title: "Book Land",
          route: "/bulkland",
        },
        {
          icon: "UsersIcon",
          color: "info",
          title: "Add New Lead",
          route: "/crm/addlead",
        },
        {
          icon: "StarIcon",
          color: "warning",
          title: "Tree",
          route: "/crm/tree",
        },
        {
          icon: "UserIcon",
          color: "warning",
          title: "Daily Working",
          route: "/hr/dailyworking",
        },
      ],
      statisticsItemsAdmin: [
        // {
        //   icon: 'TrendingUpIcon',
        //   color: 'primary',
        //   title: '230k',
        //   subtitle: 'Sales',
        //   customClass: 'mb-2 mb-xl-0'
        // },
        {
          icon: "UsersIcon",
          color: "primary",
          title: "0",
          subtitle: "Total CP",
          customClass: "mb-2 mb-xl-0",
          route: "/master/crm/channelpartner",
        },
        {
          icon: "UsersIcon",
          color: "success",
          title: "0",
          subtitle: "Total Franchise",
          customClass: "",
          route: "/master/crm/franchise",
        },
        {
          icon: "UsersIcon",
          color: "danger",
          title: "0",
          subtitle: "Total Employee",
          customClass: "",
          route: "hr/employee",
        },
        {
          icon: "UsersIcon",
          color: "warning",
          title: "0",
          subtitle: "Total Branch",
          customClass: "",
          route: "/setup/branch",
        },

        {
          icon: "rupee",
          color: "primary",
          title: "0",
          subtitle: "Today's business",
          customClass: "",
          route: "/crm/sales",
          data: [],
        },
        {
          icon: "rupee",
          color: "success",
          title: "0",
          subtitle: "This Week business",
          customClass: "",
          data: [],

          route: "/crm/sales",
        },
        {
          icon: "rupee",
          color: "danger",
          title: "0",
          subtitle: "This Month business",
          customClass: "",
          data: [],
          route: "/crm/sales",
        },
        {
          icon: "rupee",
          color: "warning",
          title: "0",
          subtitle: "This Year business",
          data: [],
          customClass: "",
          route: "/crm/sales",
        },

        {
          icon: "BoxIcon",
          color: "primary",
          title: "0",
          subtitle: "Sold Plots",
          customClass: "mb-2 mb-sm-0",
          route: "/master/crm/plotmaster",
        },
        {
          icon: "UsersIcon",
          color: "success",
          title: "0",
          subtitle: "Current Live Users",
          customClass: "",
        },

        {
          icon: "CreditCardIcon",
          color: "danger",
          title: "0",
          subtitle: "Completed Visits",
          customClass: "",
          route: "/crm/sitevisit",
        },
      ],
      demo: [
        {
          icon: "UsersIcon",
          title: "HR",
          route: "/hr",
          color: "info",
        },
        {
          icon: "PhoneIcon",
          title: "CRM",
          route: "/crm",
          color: "danger",
        },
        {
          icon: "EditIcon",
          title: "Accounting",
          route: "/accounting",
          color: "success",
        },
        {
          icon: "StarIcon",
          title: "Masters",
          route: "/master",
          color: "warning",
        },
        {
          icon: "ClipboardIcon",
          title: "Reports",
          route: "/reports",
          color: "dark",
        },
        {
          icon: "ShieldIcon",
          title: "Legal",
          route: "/legal",
          color: "danger",
        },
      ],
      userData: "",
      sidebarData: "",
      data: {},
      userRole: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      IsKyc: false,
      managerDashboard: false,
      LeadData: [],
      LeadDataItem: {},
      Tabvalue: "a",
      visitData: [],
      visitDataItem: {},
      userType: "",
      pending: false,
      rejected: false,
      leadAccess: false,
      siteVisitAccess: true,
      LeadPie: false,
      paginationTotal: 0,
      paginationStart: 0,
      paginationEnd: 0,
    };
  },
  created() {
    // data
    // this.$http.get("/analytics/data").then((response) => {
    //   this.data = response.data;
    // });
  },
  async mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    if (
      this.userData &&
      this.userData.type != "driver" &&
      this.userData.type != "farmhousemanager" &&
      this.userData.type != "guesthousemanager" &&
      this.userData.type != "sitemanager" &&
      this.userData.type != "bungalowmanager"
    ) {
      this.leadAccess = true;
    }
    if (this.userData.approved) {
      this.agreementShow = false;
    }

    this.congratulations.name = this.userData.name;

    await this.getKyc();
    if (
      this.userData.role !== "admin" &&
      this.$store.getters["app/widgetAccess"]("Project-Land-Customer details")
    ) {
      this.getDashboardWidgets();
    }
    if (this.$store.getters["app/widgetAccess"]("Lead List")) {
      this.getLeadData();
    }
    if (
      this.$store.getters["app/widgetAccess"]("Site Visit") ||
      this.userData.type == "admin"
    ) {
      this.getVisitData();
    }
    if (this.userData.role == "admin") {
      this.IsKyc = true;
      if (
        this.$store.getters["app/widgetAccess"]("Total Users Details") ||
        this.$store.getters["app/widgetAccess"]("Business Details") ||
        this.$store.getters["app/widgetAccess"]("Plot-User-Sitevisit") ||
        this.$store.getters["app/widgetAccess"]("KYC") ||
        this.userData.type == "admin"
      ) {
        this.adminDashboardWidgets();
      }
      this.totalsales();
    }

    this.userRole = this.userData.role;
    this.userType = this.userData.type;
    if (
      this.userType != "sitemanager" &&
      this.userType != "siteassistant" &&
      this.userType != "driver" &&
      this.userType != "farmhousemanager" &&
      this.userType != "guesthousemanager" &&
      this.userType != "bungalowmanager" &&
      this.userType != "channelpartner" &&
      this.userType != "admin" &&
      this.userRole != "employee"
    ) {
      this.siteVisitAccess = false;
    }

    // this.sidebarData=JSON.parse(localStorage.getItem("sidebarData"))
    if (this.IsKyc == true && this.userRole != "admin") {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      };
      await fetch(`${this.baseApi}/getSidebardata`, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          let arr = [];
          Object.keys(json.data).forEach((key) => {
            arr = [...arr, { ...json.data[key] }];
          });
          this.sidebarData = arr;
        });
      this.setRoute();
    }
  },
  methods: {
    UpdateData() {
      this.getVisitData(1);
    },
    init() {
      this.getProject();
      // this.getUsers();
      this.getLeadStage();
    },
    init1() {},
    changeTab(value) {
      this.Tabvalue = value;
    },
    async getUsers() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUsers`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.users = response.data.data;
          // this.LeadDataItem?.followupData.map((data) => {
          //   this.users.map((item) => {
          //     if (data.enterby == item.id) {
          //       data.enterby = item.name;
          //     }
          //   });
          // });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getProject() {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };

      await axios(requestOptionsUsers).then((response) => {
        this.projects = response.data.data;
      });
    },
    async handelClickAgreement() {
      localStorage.aggrement = true;
      this.agreementShow = false;
      let data = {
        approved: true,
      };

      await axios({
        method: "PUT",
        url: `${this.baseApi}/auth/userupdate/${this.userData.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
      });
    },
    async getLeadStage() {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/leadstage`,
      };

      await axios(requestOptionsUsers)
        .then((response) => {
          this.leadOption = response.data.data.data;
          //this.LeadDataItem?.followupData.map((data) => {
          //  this.leadOption.map((item) => {
          //    if (data.status == item.id) {
          //      data.status = item.name;
          //    }
          //  });
          //});
          this.$forceUpdate();
        })
        .catch((error) => console.log(error, "error"));
    },
    getLeadDataItem(item) {
      this.LeadDataItem = item;
    },
    getVisitDataItem(item) {
      this.visitDataItem = item;
      this.visitFlag = true;

      this.Tabvalue = "b";
    },
    async getVisitData(value) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/sitevisitdashboard`,
      };
      await axios(requestOptions).then((response) => {
        this.visitData = response.data.data;
        if (value) {
          this.visitData.map((item) => {
            if (item.id == this.visitDataItem.id) {
              this.visitDataItem = item;
              this.visitDataItem.timeline = this.visitDataItem.timeline
                ? JSON.parse(this.visitDataItem.timeline)
                : {
                    1: {
                      start: "",
                      end: "",
                    },
                    2: {
                      start: "",
                      end: "",
                    },
                    3: {
                      start: "",
                      end: "",
                    },
                    4: {
                      start: "",
                      end: "",
                    },
                  };
              this.visitFlag = true;
            }
          });
        } else {
          this.visitDataItem = response.data.data[0];
          this.visitFlag = true;
        }
        // if(JSON.stringify(this.visitDataItem.followupData) !== '[]' ){
        this.init1();
        // }
      });
    },
    async getLeadData() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/leaddata?pagenum=1&pagesize=10`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.LeadData = response.data.data.data;
          // this.LeadDataItem = response.data.data.data[0];
          this.paginationTotal = response.data.data.total;
          this.paginationStart = response.data.data.from;
          this.paginationEnd = response.data.data.to;
          this.LeadPie = true;

          if (JSON.stringify(this.LeadDataItem?.followupData) !== "[]") {
            this.init();
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getDashboardWidgets() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/dashboardwidgets`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }).then((response) => {
        // this.statisticsItems[0].title = response.data.data.sales;
        // this.congratulations.saleToday = response.data.data.sales;
        this.statisticsItems[0].title = response.data.data.customers;
        this.statisticsItems[1].title = response.data.data.projects;
        // this.statisticsItems[3].title = response.data.data.commission;
        this.statisticsItems[2].title = response.data.data.bulkland
          ? response.data.data.bulkland
          : 0;
      });
    },
    async adminDashboardWidgets() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/dashboarddata`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }).then((response) => {
        this.statisticsItemsAdmin[0].title = response.data.data.total_cp;
        this.statisticsItemsAdmin[1].title = response.data.data.total_franchise;
        this.statisticsItemsAdmin[2].title = response.data.data.total_emp;
        this.statisticsItemsAdmin[3].title = response.data.data.totalBranches;

        this.statisticsItemsAdmin[4].title = response.data.data.todayBusiness.toLocaleString(
          "en-IN"
        );
        this.statisticsItemsAdmin[5].title = response.data.data.thisweekBusiness.toLocaleString(
          "en-IN"
        );
        this.statisticsItemsAdmin[6].title = response.data.data.thismonthBusiness.toLocaleString(
          "en-IN"
        );
        this.statisticsItemsAdmin[7].title = response.data.data.thisyearBusiness.toLocaleString(
          "en-IN"
        );
        this.statisticsItemsAdmin[8].title = response.data.data.sold_plots;
        this.statisticsItemsAdmin[9].title = response.data.data.live_users;
        this.statisticsItemsAdmin[10].title = response.data.data.completed_visit;
      });
    },
    OfferClick() {
      this.$router.push("/pages/offers");
    },
    async getKyc() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${this.userData.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }).then((response) => {
        this.userData = response.data.data[0];
        this.IsKyc =
          this.userData.is_kyc == 1 || this.userData.role == "employee" ? true : false;
        if (this.userData.is_kyc == 0) {
          this.pending = true;
        } else if (this.userData.is_kyc == 2) {
          this.rejected = true;
        }
        if (!this.IsKyc) {
          this.$refs.mymodal.show();
        }
        // this.IsKyc=this.userData.aadharbackside!=null && this.userData.aadharfrontside!=null && this.userData.aadharcardno!=null && this.userData.pancard!=null
      });
    },
    kFormatter,
    setRoute() {
      this.sidebarData.map((item) => {
        this.demo.map((inner) => {
          if (inner.title == item.title) {
            item.route = inner.route;
            item.color = inner.color;
          }
        });
      });
    },
    async totalsales() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/totalsale`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }).then((response) => {
        this.statisticsItemsAdmin[4].data = response.data.data.today_sale;

        let weeklyData = response.data.data.thisweek_sale;
        this.statisticsItemsAdmin[5].data = [0, 0, 0, 0, 0, 0, 0];
        let monthlyData = response.data.data.thismonth_sale;
        for (let index = 0; index < 31; index++) {
          this.statisticsItemsAdmin[6].data[index] = 0;
        }
        this.statisticsItemsAdmin[7].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let yearlyData = response.data.data.thisyear_sale;

        this.statisticsItemsAdmin[4].data.map((item, index) => {
          this.statisticsItemsAdmin[4].data[index] = item.totalpayment;
        });
        this.statisticsItemsAdmin[4].data = [
          { data: this.statisticsItemsAdmin[4].data, name: "sales" },
        ];

        weeklyData.map((item) => {
          this.statisticsItemsAdmin[5].data[item.weekDay - 1] = item.totalpayment;
        });
        this.statisticsItemsAdmin[5].data = [
          { data: this.statisticsItemsAdmin[5].data, name: "sales" },
        ];

        monthlyData.map((item) => {
          this.statisticsItemsAdmin[6].data[item.days - 1] = item.totalpayment;
        });
        this.statisticsItemsAdmin[6].data = [
          { data: this.statisticsItemsAdmin[6].data, name: "sales" },
        ];

        // this.statisticsItemsAdmin[6].data.map((item, index) => {
        //   this.statisticsItemsAdmin[6].data[index] = item.totalpayment;
        // });
        // this.statisticsItemsAdmin[6].data = [
        //   { data: this.statisticsItemsAdmin[6].data, name: "sales" },
        // ];

        yearlyData.map((item) => {
          this.statisticsItemsAdmin[7].data[item.month - 1] = item.totalpayment;
        });
        this.statisticsItemsAdmin[7].data = [
          { data: this.statisticsItemsAdmin[7].data, name: "sales" },
        ];

        for (let index = 4; index < 8; index++) {
          if (this.statisticsItemsAdmin[index].data[0].data.length == 0) {
            if (this.statisticsItemsAdmin[index].data[0].data.length == 0) {
              this.statisticsItemsAdmin[index].data[0].data = [0, 0];
            } else {
              this.statisticsItemsAdmin[
                index
              ].data[0].data[1] = this.statisticsItemsAdmin[index].data[0].data[0];
              this.statisticsItemsAdmin[index].data[0].data[0] = 0;
            }
          }
        }
      });
    },
  },
};
</script>
<style>
.example::-webkit-scrollbar {
  display: none;
}
</style>
<style scop>
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.441);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 128, 128, 0.941);
}
</style>
