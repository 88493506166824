<template>
  <div class="row justify-content-between">
    <div
      v-for="(item, index) in viewFields"
      :key="index"
      :class="item.class"
      class="mt-2"
    >
      <div v-if="item.type == 'image'">
        <label class="viewLabel">{{ item.label }}</label>
        <b-media-aside>
          <b-link>
            <b-img
              ref="previewEl"
              rounded
              :src="
                data[item.field] && data[item.field] !== null
                  ? data[item.field].includes(imagePath)
                    ? data[item.field]
                    : imagePath + data[item.field]
                  : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
              "
              height="80"
            />
          </b-link>
        </b-media-aside>
      </div>
      <div v-else-if="item.type == 'text'">
        <label class="viewLabel">{{ item.label }} :</label>
        <p class="viewValue">{{ data[item.field] }}</p>
      </div>
      <div v-else-if="item.type == 'date'">
        <label class="viewLabel">{{ item.label }} :</label>
        <p class="viewValue">
          {{ data[item.field] ? moment(data[item.field]).format("DD/MM/YYYY") : "-" }}
        </p>
      </div>
      <div v-else-if="item.type == 'datetime'">
        <label class="viewLabel">{{ item.label }} :</label>
        <p class="viewValue">
          {{
            data[item.field] ? moment(data[item.field]).format("DD/MM/YYYY h:m A") : "-"
          }}
        </p>
      </div>
      <div v-else-if="item.type == 'fieldInObj'">
        <label class="viewLabel">{{ item.label }} :</label>
        <p class="viewValue" v-if="data[item.field] && item.childFieldType == 'text'">
          {{ data[item.field][item.childField] }}
        </p>
        <attachment
          :data="data[item.field][item.childField]"
          v-else-if="item.childFieldType == 'attachment' && data[item.field]"
        />
      </div>
      <div v-else-if="item.type == 'email'">
        <label class="viewLabel">{{ item.label }} :</label>
        <p class="viewValue">{{ data[item.field] }}</p>
      </div>
      <div v-else-if="item.field == 'anniversarydate' && data.maritalstatus == 'Married'">
        <label class="viewLabel">{{ item.label }} :</label>
        <p class="viewValue">{{ data[item.field] }}</p>
      </div>
      <div v-else-if="item.type == 'heading'" class="mb-1 mt-3">
        <span style="font-weight: 500; font-size: 1.285rem">{{ item.label }}</span>
      </div>

      <div
        v-else-if="
          item.type == 'dropdown' &&
          (loginData.role == 'admin' || data.id != loginData.id)
        "
      >
        <label class="viewLabel">{{ item.label }} :</label>
        <p class="viewValue" v-if="data[item.field] && Array.isArray(data[item.field])">
          {{ data[item.field] ? data[item.field][0][item.responseValue] : "" }}
        </p>
        <p class="viewValue" v-else-if="data[item.field]">
          {{ data[item.field] ? data[item.field][item.responseValue] : "" }}
        </p>
      </div>
      <div v-else-if="item.type == 'attachment'">
        <label class="viewLabel">{{ item.label }} :</label>
        <p><attachment :data="data[item.field]" /></p>
      </div>
      <div
        v-else-if="
          item.type == 'leadaccess' && data.position && data.position.name == 'leadmanage'
        "
      >
        <label class="viewLabel">{{ item.label }} :</label>
        <p class="viewValue">{{ data[item.field] }}</p>
      </div>
      <div v-else-if="item.type == 'multiple'">
        <div style="width: 100%; max-height: 500px; overflow: auto">
          <table class="table table-bordered" style="min-width: 15cm">
            <thead class="text">
              <tr>
                <th>Sr. No</th>
                <th v-for="(th, thIndex) in item.tableHead" :key="thIndex">{{ th }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(td, id) in typeof data[item.field] == 'string'
                  ? JSON.parse(data[item.field])
                  : data[item.field]"
                :key="id"
              >
                <td>{{ id + 1 }}</td>
                <td v-for="(multi, multiIn) in item.tableField" :key="multiIn">
                  <div v-if="multi.type == 'text'">
                    {{ td[multi.field] }}
                  </div>
                  <div v-if="multi.type == 'attachment'">
                    <attachment :data="td[multi.field]" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <slot name="extraView" :data="data" :item="item"></slot>
    </div>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "@/components/Attechment.vue";
export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,
    Attachment,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  props: {
    viewFields: {
      default: [],
    },
    title: {
      default: "",
    },
    moduleapi: {
      default: "",
    },
    closepopup: {
      default: () => {},
    },
    getEditValue: {
      default: "",
    },
    selectedID: {
      default: 0,
    },
  },
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      moment,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      data: [],
      loginData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await axios({
        method: "GET",
        url: `${this.getEditValue}/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.data = Array.isArray(json.data.data) ? json.data.data[0] : json.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>
<style scoped>
.viewLabel {
  font-size: 15px !important;
  font-weight: 500 !important;
}
.viewValue {
  font-size: 15px !important;
  color: black;
}
</style>
